import React from 'react';
// reactstrap components
import {
    Label,
    FormGroup,
    Input,
    Row,
    Col,
} from "reactstrap";

// import ImageUpload from "components/CustomUpload/AnnexUpload.jsx";
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";

export default class TipoPessoaForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            alert: null,
            tipoPessoa: null,
            requerente: null,
            cnpj_cpf: ''
        }

    }

    componentWillReceiveProps(props) {
        if (props.atualizar != this.props.atualizar) {
            this.resetText();
        }
    }

    // to stop the warning of calling setState of unmounted component
    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        setTimeout(this.hideAlert, tempo);
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    getPessoaTipo() {
        var tipo = "PF";
        if (this.state.tipoPessoa) {
            tipo = this.state.tipoPessoa;
        }
        return tipo;
    }

    resetText = () => {
        this.setState({ cnpj_cpf: '' });
    }

    onChangeText = (event) => {
        this.setState({ cnpj_cpf: event.target.value });
        if (typeof (this.props.onChange) === 'function') {
            this.props.onChange(event.target.value);
        }
    }

    render() {

        return (
            <>
                {this.state.alert}
                <FormGroup>
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultChecked
                                defaultValue="PF"
                                name={"radioTipoPessoa" + this.props.tipo}
                                type="radio"
                                onChange={(event) => { this.setState({ tipoPessoa: event.target.value }) }}
                            />
                            Pessoa Física <span className="form-check-sign" />
                        </Label>
                    </div>
                    <div className="form-check-radio">
                        <Label check>
                            <Input
                                defaultValue="PJ"
                                name={"radioTipoPessoa" + this.props.tipo}
                                type="radio"
                                onChange={(event) => { this.setState({ tipoPessoa: event.target.value }) }}
                            />
                            Pessoa Jurídica <span className="form-check-sign" />
                        </Label>
                    </div>
                </FormGroup>
                <label>Digita aqui o {this.getPessoaTipo() == "PF" ? "CPF" : "CNPJ"} do {this.props.tipo}</label>
                <FormGroup>
                    <Row>
                        <Col md="6">
                            <InputMask
                                className="form-control"
                                placeholder={this.getPessoaTipo() == "PF" ? "CPF do " + this.props.tipo : "CNPJ do " + this.props.tipo}
                                type="text"
                                onChange={this.onChangeText}
                                mask={this.getPessoaTipo() == "PF" ? "999.999.999-99" : "99.999.999/9999-99"}
                                value={this.state.cnpj_cpf}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }
}