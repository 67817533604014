import React from "react";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Input, Row, Col } from "reactstrap";

import InputMask from "react-input-mask";

export default class MaquinaForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numero_chip: "",
            operadoraOptions: [
                { value: "Claro", label: "Claro" },
                { value: "Oi", label: "Oi" },
                { value: "Vivo", label: "Vivo" },
                { value: "TIM", label: "TIM" }
            ],
            maquinaOptions: [
                { value: "BEVIMais", label: "BEVIMais" },
                { value: "BEVIMais D+2", label: "BEVIMais D+2" },
                { value: "BEVITop", label: "BEVITop" }
            ],
            operadora: null,
            mask: "",
            numero_serie: "",
            maquina: null
        };
    }

    onAdd = () => {
        if (typeof this.props.onAdd === "function") {
            this.props.onAdd();
        }
    };

    onRemove = () => {
        if (typeof this.props.onRemove === "function") {
            this.props.onRemove();
        }
    };

    updateNumero = event => {
        this.setState({ numero_chip: event.target.value });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: event.target.value,
                operadora: this.state.operadora,
                numero_serie: this.state.numero_serie,
                maquina: this.state.maquina
            });
        }
    };

    updateNumeroSerie = event => {
        this.setState({ numero_serie: event.target.value });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: this.state.numero_chip,
                operadora: this.state.operadora,
                numero_serie: event.target.value,
                maquina: this.state.maquina
            });
        }
    };

    updateOperadora = value => {
        console.log("value", value);
        var mask = "9999.9999.9999.9999.9999.9999";
        if (value.value === "Vivo") {
            mask = "99999.99999.99999.99999";
        } else if (value.value === "Oi") {
            mask = "999999.99999.9999.9999";
        } else if (value.value === "Claro") {
            mask = "99999.99999.99999.99999";
        }
        this.setState({ operadora: value, mask });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: this.state.numero_chip,
                operadora: value,
                numero_serie: this.state.numero_serie,
                maquina: this.state.maquina
            });
        }
    };

    updateMaquina = value => {
        this.setState({ maquina: value });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: this.state.numero_chip,
                operadora: this.state.operadora,
                numero_serie: this.state.numero_serie,
                maquina: value
            });
        }
    };

    render() {
        return (
            <>
                <Row>
                    <Col md="2">
                        <label>Máquina</label>
                        <Select
                            name="maquina"
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={this.state.maquina}
                            onChange={this.updateMaquina}
                            options={this.state.maquinaOptions}
                            placeholder=""
                        />
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Número de Série</label>
                            <Input
                                type="text"
                                value={this.state.numero_serie}
                                onChange={this.updateNumeroSerie}
                                className="form-control"
                            />
                        </FormGroup>
                    </Col>

                    <Col md="2">
                        <label>Operadora</label>
                        <Select
                            name="operadora"
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={this.state.operadora}
                            onChange={this.updateOperadora}
                            options={this.state.operadoraOptions}
                            placeholder=""
                        />
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label>Número do Chip</label>
                            <InputMask
                                type="text"
                                value={this.state.numero_chip}
                                onChange={this.updateNumero}
                                className="form-control"
                                mask={this.state.mask}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="1">
                        <FormGroup>
                            <Button
                                style={{ marginTop: "24px" }}
                                onClick={this.onAdd}
                                color="success"
                            >
                                +
                            </Button>
                        </FormGroup>
                    </Col>
                    {this.props.item.idx != 0 && (
                        <Col md="1">
                            <FormGroup>
                                <Button
                                    style={{ marginTop: "24px" }}
                                    onClick={this.onRemove}
                                    color="danger"
                                >
                                    -
                                </Button>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </>
        );
    }
}
