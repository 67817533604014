import React from "react";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Input, Row, Col } from "reactstrap";

import InputMask from "react-input-mask";

export default class ChipForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numero_chip: "",
            operadoraOptions: [
                { value: "Claro", label: "Claro" },
                { value: "Oi", label: "Oi" },
                { value: "Vivo", label: "Vivo" },
                { value: "TIM", label: "TIM" }
            ],
            operadora: null,
            mask: ""
        };
    }

    onAdd = () => {
        if (typeof this.props.onAdd === "function") {
            this.props.onAdd();
        }
    };

    onRemove = () => {
        if (typeof this.props.onRemove === "function") {
            this.props.onRemove();
        }
    };

    updateNumero = event => {
        this.setState({ numero_chip: event.target.value });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: event.target.value,
                operadora: this.state.operadora
            });
        }
    };

    updateOperadora = value => {
        console.log("value", value);
        var mask = "9999.9999.9999.9999.9999.9999";
        if (value.value === "Vivo") {
            mask = "99999.99999.99999.99999";
        } else if ((value.value === "Oi")) {
            mask = "999999.99999.9999.9999";
        } else if ((value.value === "Claro")) {
            mask = "99999.99999.99999.99999";
        }
        console.log("qual mask", mask);
        this.setState({ operadora: value, mask });
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate({
                numero_chip: this.state.numero_chip,
                operadora: value
            });
        }
    };

    render() {
        return (
            <Row>
                <Col md="4">
                    <label>Operadora</label>
                    <Select
                        name="operadora"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={this.state.operadora}
                        onChange={this.updateOperadora}
                        options={this.state.operadoraOptions}
                        placeholder=""
                    />
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label>Número</label>
                        <InputMask
                            type="text"
                            value={this.state.numero_chip}
                            onChange={this.updateNumero}
                            className="form-control"
                            mask={this.state.mask}
                        />
                    </FormGroup>
                </Col>
                <Col md="1">
                    <FormGroup>
                        <Button
                            style={{ marginTop: "24px" }}
                            onClick={this.onAdd}
                            color="success"
                        >
                            +
                        </Button>
                    </FormGroup>
                </Col>
                {this.props.item.idx != 0 && (
                    <Col md="1">
                        <FormGroup>
                            <Button
                                style={{ marginTop: "24px" }}
                                onClick={this.onRemove}
                                color="danger"
                            >
                                -
                            </Button>
                        </FormGroup>
                    </Col>
                )}
            </Row>
        );
    }
}
