var config_env = {
    API: "https://api-conta.lucree.com.br/solicitacoes/v1/",
    API_AUTH: "https://api-conta.lucree.com.br/usuarios/",
    API_VIACEP: "https://viacep.com.br/ws/",
    FAQ_QUERY: "faq/",
    MOTIVOS_QUERY: "motivos/",
    PESSOAS_FILTER_CNPJ_CPF: "pessoas/?cnpj_cpf=",
    PESSOAS_FILTER_EMAIL: "pessoas/?email=",
    SOLICITACOES_FILTER_EMAIL: "solicitacoes/?solicitante_email=",
    SOLICITACOES_FILTER_DOC: "solicitacoes/doc?cliente_cnpj_cpf=",
    SOLICITACOES_BY_ID: "solicitacoes/",
    SOLICITACOES_BY_CLIENTE_CNPJ_CPF: "solicitacoes/?cliente_cnpj_cpf=",
    DEPARTAMENTOS: "departamentos/",
    SOLICITACOES_FILTER_DEPARTAMENTOS: "solicitacoes/?departamento_array=",
    COMENTARIOS_FILTER_SOLICITACAO: "comentarios/?solicitacao_id=",
    ANEXOS_FILTER_SOLICITACAO: "anexos/?solicitacao_id=",
    CAMPOS_FILTER_SOLICITACAO: "campos/?solicitacao_id=",
    ATIVIDADES_FILTER_SOLICITACAO: "atividades/?solicitacao_id=",
    CLIENTE_POR_DOCUMENTO: "cliente/?cnpj_cpf=",
    ENDERECO_POR_DOCUMENTO: "endereco/?cnpj_cpf=",
    DADOS_BANCARIOS_POR_DOCUMENTO: "dados_bancarios/?cnpj_cpf=",
    DADOS_PESSOAIS_POR_DOCUMENTO: "dados_pessoais/?cnpj_cpf=",
    PEDIDOS_POR_DOCUMENTO: "pedidos/?cnpj_cpf=",
    AUTH_TOKEN_QUERY: "api-token-auth/",
    RESET_TOKEN_VERIFY: "reset-pass/verify/?token=",
    RESET_TICKET: "reset-ticket/?email=",
    RESET_PASS: "reset-pass/?token=",
    VERIFY_EMAIL: "verify/?email=",
    REFRESH_TOKEN_QUERY: "api-token-refresh/",
    ANEXOS_QUERY: "anexos/",

    AUTH_TOKEN: "Token 5c05927aa03836496300116b261fcc0278626153",

    LIMITE_UPLOAD_BYTES: 2097152,
    LIMITE_UPLOAD_MB: "2MB"
};

function noop() {}

if (process.env.NODE_ENV !== "development") {
    console.log("location", window.location);
    if (window.location.hostname.indexOf("-homolog") !== -1) {
        // homolog
        console.log("ambiente homolog");
        config_env = {
            ...config_env,
            API: "https://api-conta-hml.lucree.com.br/solicitacoes/v1/",
            API_AUTH: "https://api-conta-hml.lucree.com.br/usuarios/"
        };
    } else {
        // production
        console.log("ambiente prod");
        console.log = noop;
        console.warn = noop;
        console.error = noop;
    }
} else {
    console.log("ambiente dev");
    console.log("env var", process.env.NODE_ENV);
    console.log("location", window.location);
    config_env = {
        ...config_env,
        API: "https://api-conta-hml.lucree.com.br/solicitacoes/v1/",
        //API: "http://localhost:8000/v1/",
        API_AUTH: "https://api-conta-hml.lucree.com.br/usuarios/"
    };
}

export const config = config_env;
