import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import logo from "assets/img/V3_GG.png";
import logo_normal from "assets/img/V3_G.png";
var ps;

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getCollapseStates(props.routes);
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (
                routes[i].collapse &&
                this.getCollapseInitialState(routes[i].views)
            ) {
                return true;
            } else if (
                window.location.pathname.indexOf(routes[i].path) !== -1
            ) {
                return true;
            }
        }
        return false;
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1
            ? "active"
            : "";
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        const { props } = this;
        return routes.map((prop, key) => {
            let route_verified = false;
            if (props.user) {
                return props.user.groups.map(item => {
                    const permissions = prop.permission;
                    if ((permissions.includes("All") || permissions.includes(item.name)) && route_verified == false){
                        route_verified = true;
                        return (
                            <MenuItem
                                prop={prop}
                                key={key}
                                routes={props.routes}
                                location={props.location}
                                user={props.user}
                            />
                        );
                    }
                    return;
                });
            }
        });
    };

    render() {
        const { prop, key } = this.props;
        //console.log(this.props);
        if (prop.redirect) {
            return null;
        }
        if (prop.collapse) {
            var st = {};
            st[prop["state"]] = !this.state[prop.state];
            //console.log('state', this.state);
            //console.log('prop state', prop.state);
            //console.log('st', st);
            return (
                <li
                    className={
                        this.getCollapseInitialState(prop.views) ? "active" : ""
                    }
                    key={key}
                >
                    <a
                        href="#"
                        data-toggle="collapse"
                        aria-expanded={this.state[prop.state]}
                        onClick={e => {
                            e.preventDefault();
                            this.setState(st);
                        }}
                    >
                        {prop.icon !== undefined ? (
                            <>
                                <i className={prop.icon} />
                                <p>
                                    {prop.name}
                                    <b className="caret" />
                                </p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">
                                    {prop.mini}
                                </span>
                                <span className="sidebar-normal">
                                    {prop.name}
                                    <b className="caret" />
                                </span>
                            </>
                        )}
                    </a>
                    <Collapse isOpen={this.state[prop.state]}>
                        <ul className="nav">{this.createLinks(prop.views)}</ul>
                    </Collapse>
                </li>
            );
        }
        return (
            <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                <NavLink to={prop.layout + prop.path} activeClassName="">
                    {prop.icon !== undefined ? (
                        <>
                            <i className={prop.icon} />
                            <p>{prop.name}</p>
                        </>
                    ) : (
                        <>
                            <span className="sidebar-mini-icon">
                                {prop.mini}
                            </span>
                            <span className="sidebar-normal">{prop.name}</span>
                        </>
                    )}
                </NavLink>
            </li>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        //console.log('this.props.location', this.props.location)
    }

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        const { props } = this;
        return routes.map((prop, key) => {
            let route_verified = false;
            if (props.user) {
                return props.user.groups.map(item => {
                    const permissions = prop.permission;
                    if ((permissions.includes("All") || permissions.includes(item.name)) && route_verified == false){
                        route_verified = true;
                        return (
                            <MenuItem
                                prop={prop}
                                key={key}
                                routes={props.routes}
                                location={props.location}
                                user={props.user}
                            />
                        );
                    }
                    return;
                });
            }
        });
    };

    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebar, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        return (
            <div
                className="sidebar"
                data-color={"white"}
                data-active-color={"info"}
            >
                <div className="logo">
                    <a href="/" className="simple-text logo-mini">
                        <div className="logo-img">
                            <img src={logo} alt="react-logo" />
                        </div>
                    </a>
                    <a href="/" className="simple-text logo-normal">
                        <img
                            style={{ maxWidth: 115 }}
                            src={logo_normal}
                            alt="react-logo-normal"
                        />
                    </a>
                </div>

                <div className="sidebar-wrapper" ref="sidebar">
                    <Nav>{this.createLinks(this.props.routes)}</Nav>
                </div>
            </div>
        );
    }
}

export default Sidebar;
