import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Input,
    FormText,
    Label,
    Button
} from "reactstrap";
import axios from "axios";
import { config } from "../../config";
import Dropzone from "react-dropzone";
import { solicitacoes } from "../../helpers/autoatendimento/solicitacoes";
import Select from "react-select";
import RetornoChamado from "./FormRetorno";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default class FormFaturamento extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            filesEntrada: [],
            comentario: null,
            devolver: "",
            numero_nf: "",
            numero_nf_entrada: "",
            numero_nf_saida: "",
            devolverOptions: [
                { label: "Cadastro", value: "Cadastro" },
                { label: "Suporte", value: "Suporte" }
            ]
        };
    }

    // Atualizando etapa do chamado
    atualizaEtapa = etapa => {
        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            etapa
        };

        axios
            .post(config.API + "SolicitacaoUpdateEtapa/", postdata, {
                headers: headers
            })
            .then(response => {});
    };

    onDrop = files => {
        this.setState({ files });
    };

    onDropEntrada = files => {
        this.setState({ filesEntrada: files });
    };

    onPressEncaminharFaturamento = () => {
        const self = this;

        if (this.validaTamanhoArquivos()) {
            if (this.state.numero_nf === "") {
                this.props.onEventoErro(
                    "Preencha o campo Número da Nota Fiscal!"
                );
                return;
            }

            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (this.state.files.length) {
                this.state.files.forEach(file => {
                    const data = new FormData();
                    data.append("path", file, file.name);
                    data.append("descricao", file.name);
                    data.append("solicitacao_id", this.props.solicitacao_id);
                    data.append(
                        "analista_departamento",
                        this.props.analista_departamento
                    );
                    data.append("departamento", this.props.departamento);
                    data.append("enviarParaFlash", true);
                    data.append("enviarParaDepartamento", 6);

                    axios
                        .post(config.API + config.ANEXOS_QUERY, data, {
                            onUploadProgress: ProgressEvent => {
                                self.setState({
                                    loaded:
                                        (ProgressEvent.loaded /
                                            ProgressEvent.total) *
                                        100
                                });
                            },
                            headers: headers
                        })
                        .then(res => {
                            // Se deu tudo certo, atualiza o campo de nota fiscal
                            const postdata = {
                                solicitacao_id: self.props.solicitacao_id,
                                tipo_id: 13,
                                descricao: self.state.numero_nf,
                                analista_departamento:
                                    self.props.analista_departamento,
                                departamento_id: 5
                            };

                            axios
                                .post(config.API + "campos/", postdata, {
                                    headers: headers
                                })
                                .then(function(response) {
                                    // Se deu tudo certo, atualiza a etapa
                                    self.atualizaEtapa(3);

                                    if (
                                        typeof self.props.onEventoSucesso ===
                                        "function"
                                    ) {
                                        self.props.onEventoSucesso(
                                            response.data.descricao
                                        );
                                    }
                                })
                                .catch(function(error) {
                                    /* console.log('error aqui 1', error);
                                    if (error.response) {
                                        console.log('error aqui 2', error.response.data);
                                        console.log('error aqui 3', error.response.status);
                                        console.log('error aqui 4', error.response.headers);
                                    } else if (error.request) {
                                        console.log('error aqui 5', error.request);
                                    } else {
                                        console.log('error aqui 6', error.message);
                                    }
                                    console.log('error aqui 7', error.config); */

                                    if (
                                        typeof self.props.onEventoErro ===
                                        "function"
                                    ) {
                                        self.props.onEventoErro(
                                            "Não foi possível encaminhar a solicitação!"
                                        );
                                    }
                                });
                        })
                        .catch(error => {
                            console.log("error aqui 1", error);
                            if (error.response) {
                                console.log(
                                    "error aqui 2",
                                    error.response.data
                                );
                                console.log(
                                    "error aqui 3",
                                    error.response.status
                                );
                                console.log(
                                    "error aqui 4",
                                    error.response.headers
                                );
                            } else if (error.request) {
                                console.log("error aqui 5", error.request);
                            } else {
                                console.log("error aqui 6", error.message);
                            }
                            console.log("error aqui 7", error.config);

                            if (typeof self.props.onEventoErro === "function") {
                                self.props.onEventoErro(
                                    "Não foi possível encaminhar a solicitação!"
                                );
                            }
                        });
                });
            } else {
                if (typeof this.props.onEventoErro === "function") {
                    this.props.onEventoErro(
                        "Preencha o campo do XML da Nota Fiscal!"
                    );
                }
            }
        }
    };

    onPressEncaminharFaturamento2 = () => {
        const self = this;

        if (this.state.numero_nf_entrada === "") {
            this.props.onEventoErro(
                "Preencha o campo Número da Nota Fiscal de Entrada!"
            );
            return;
        }

        if (this.state.numero_nf_saida === "") {
            this.props.onEventoErro(
                "Preencha o campo Número da Nota Fiscal de Saída!"
            );
            return;
        }

        if (
            this.validaTamanhoArquivos() &&
            this.validaTamanhoArquivosEntrada()
        ) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (this.state.files.length && this.state.filesEntrada.length) {
                this.state.files.forEach(file => {
                    const data = new FormData();
                    data.append("path", file, file.name);
                    data.append("descricao", file.name);
                    data.append("solicitacao_id", this.props.solicitacao_id);
                    data.append(
                        "analista_departamento",
                        this.props.analista_departamento
                    );
                    data.append("departamento", this.props.departamento);
                    data.append("enviarParaFlash", true);
                    data.append("enviarParaDepartamento", 6);

                    axios
                        .post(config.API + config.ANEXOS_QUERY, data, {
                            onUploadProgress: ProgressEvent => {
                                self.setState({
                                    loaded:
                                        (ProgressEvent.loaded /
                                            ProgressEvent.total) *
                                        100
                                });
                            },
                            headers: headers
                        })
                        .then(res => {
                            //console.log(res.statusText)

                            // Se deu tudo certo, atualiza o campo de nota fiscal de entrada
                            const postdata = {
                                solicitacao_id: self.props.solicitacao_id,
                                tipo_id: 14,
                                descricao: self.state.numero_nf_entrada,
                                analista_departamento:
                                    self.props.analista_departamento,
                                departamento_id: 5
                            };

                            axios
                                .post(config.API + "campos/", postdata, {
                                    headers: headers
                                })
                                .then(function(response) {
                                    // Se deu tudo certo, atualiza o campo de nota fiscal de saida
                                    const postdata = {
                                        solicitacao_id:
                                            self.props.solicitacao_id,
                                        tipo_id: 15,
                                        descricao: self.state.numero_nf_saida,
                                        analista_departamento:
                                            self.props.analista_departamento,
                                        departamento_id: 5
                                    };

                                    axios
                                        .post(
                                            config.API + "campos/",
                                            postdata,
                                            {
                                                headers: headers
                                            }
                                        )
                                        .then(function(response) {
                                            // Se deu tudo certo, atualiza a etapa
                                            self.atualizaEtapa(5);

                                            if (
                                                typeof self.props
                                                    .onEventoSucesso ===
                                                "function"
                                            ) {
                                                self.props.onEventoSucesso(
                                                    response.data.descricao
                                                );
                                            }
                                        })
                                        .catch(function(error) {
                                            /* console.log('error aqui 1', error);
                                            if (error.response) {
                                                console.log('error aqui 2', error.response.data);
                                                console.log('error aqui 3', error.response.status);
                                                console.log('error aqui 4', error.response.headers);
                                            } else if (error.request) {
                                                console.log('error aqui 5', error.request);
                                            } else {
                                                console.log('error aqui 6', error.message);
                                            }
                                            console.log('error aqui 7', error.config); */

                                            if (
                                                typeof self.props
                                                    .onEventoErro === "function"
                                            ) {
                                                self.props.onEventoErro(
                                                    "Não foi possível encaminhar a solicitação!"
                                                );
                                            }
                                        });
                                })
                                .catch(function(error) {
                                    /* console.log('error aqui 1', error);
                                    if (error.response) {
                                        console.log('error aqui 2', error.response.data);
                                        console.log('error aqui 3', error.response.status);
                                        console.log('error aqui 4', error.response.headers);
                                    } else if (error.request) {
                                        console.log('error aqui 5', error.request);
                                    } else {
                                        console.log('error aqui 6', error.message);
                                    }
                                    console.log('error aqui 7', error.config); */

                                    if (
                                        typeof self.props.onEventoErro ===
                                        "function"
                                    ) {
                                        self.props.onEventoErro(
                                            "Não foi possível encaminhar a solicitação!"
                                        );
                                    }
                                });
                        })
                        .catch(error => {
                            if (typeof self.props.onEventoErro === "function") {
                                self.props.onEventoErro(
                                    "Não foi possível encaminhar a solicitação!"
                                );
                            }
                        });
                });

                this.state.filesEntrada.forEach(file => {
                    const data = new FormData();
                    data.append("path", file, file.name);
                    data.append("descricao", file.name);
                    data.append("solicitacao_id", this.props.solicitacao_id);
                    data.append(
                        "analista_departamento",
                        this.props.analista_departamento
                    );
                    data.append("departamento", this.props.departamento);
                    data.append("enviarParaFlash", false);
                    data.append("enviarParaDepartamento", 6);

                    axios
                        .post(config.API + config.ANEXOS_QUERY, data, {
                            onUploadProgress: ProgressEvent => {
                                self.setState({
                                    loaded:
                                        (ProgressEvent.loaded /
                                            ProgressEvent.total) *
                                        100
                                });
                            },
                            headers: headers
                        })
                        .then(res => {
                            //console.log(res.statusText)

                            // Se deu tudo certo, atualiza a etapa
                            self.atualizaEtapa(5);

                            if (
                                typeof self.props.onEventoSucesso === "function"
                            ) {
                                self.props.onEventoSucesso(res.data.descricao);
                            }
                        })
                        .catch(error => {
                            if (typeof self.props.onEventoErro === "function") {
                                self.props.onEventoErro(
                                    "Não foi possível encaminhar a solicitação!"
                                );
                            }
                        });
                });
            } else {
                if (typeof this.props.onEventoErro === "function") {
                    this.props.onEventoErro(
                        "Preencha os campos de XML de Entrada e Saída da Nota Fiscal!"
                    );
                }
            }
        }
    };

    onPressEncaminharFaturamento3 = () => {
        const self = this;

        if (this.state.numero_nf_entrada === "") {
            this.props.onEventoErro(
                "Preencha o campo Número da Nota Fiscal de Entrada!"
            );
            return;
        }

        if (this.validaTamanhoArquivosEntrada()) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (this.state.filesEntrada.length) {
                this.state.filesEntrada.forEach(file => {
                    const data = new FormData();
                    data.append("path", file, file.name);
                    data.append("descricao", file.name);
                    data.append("solicitacao_id", this.props.solicitacao_id);
                    data.append(
                        "analista_departamento",
                        this.props.analista_departamento
                    );
                    data.append("departamento", this.props.departamento);
                    data.append("enviarParaFlash", false);
                    data.append("enviarParaDepartamento", 4);

                    axios
                        .post(config.API + config.ANEXOS_QUERY, data, {
                            onUploadProgress: ProgressEvent => {
                                self.setState({
                                    loaded:
                                        (ProgressEvent.loaded /
                                            ProgressEvent.total) *
                                        100
                                });
                            },
                            headers: headers
                        })
                        .then(res => {
                            //console.log(res.statusText)

                            // Se deu tudo certo, atualiza o campo de nota fiscal
                            const postdata = {
                                solicitacao_id: self.props.solicitacao_id,
                                tipo_id: 14,
                                descricao: self.state.numero_nf_entrada,
                                analista_departamento:
                                    self.props.analista_departamento,
                                departamento_id: 5
                            };

                            axios
                                .post(config.API + "campos/", postdata, {
                                    headers: headers
                                })
                                .then(function(response) {
                                    // Se deu tudo certo, atualiza a etapa
                                    self.atualizaEtapa(5);

                                    if (
                                        typeof self.props.onEventoSucesso ===
                                        "function"
                                    ) {
                                        self.props.onEventoSucesso(
                                            response.data.descricao
                                        );
                                    }
                                })
                                .catch(function(error) {
                                    /* console.log('error aqui 1', error);
                                    if (error.response) {
                                        console.log('error aqui 2', error.response.data);
                                        console.log('error aqui 3', error.response.status);
                                        console.log('error aqui 4', error.response.headers);
                                    } else if (error.request) {
                                        console.log('error aqui 5', error.request);
                                    } else {
                                        console.log('error aqui 6', error.message);
                                    }
                                    console.log('error aqui 7', error.config); */

                                    if (
                                        typeof self.props.onEventoErro ===
                                        "function"
                                    ) {
                                        self.props.onEventoErro(
                                            "Não foi possível encaminhar a solicitação!"
                                        );
                                    }
                                });
                        })
                        .catch(error => {
                            if (typeof self.props.onEventoErro === "function") {
                                self.props.onEventoErro(
                                    "Não foi possível encaminhar a solicitação!"
                                );
                            }
                        });
                });
            } else {
                if (typeof this.props.onEventoErro === "function") {
                    this.props.onEventoErro(
                        "Preencha o campo de XML de Entrada da Nota Fiscal!"
                    );
                }
            }
        }
    };

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo !== 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    validaTamanhoArquivos() {
        // Validando tamanho dos arquivos
        if (this.state.files.length) {
            var size = 0;
            for (var i = 0; i < this.state.files.length; i++) {
                const file = this.state.files[i];
                size += file.size;
            }

            if (size > config.LIMITE_UPLOAD_BYTES) {
                this.autoCloseAlert(
                    "Erro",
                    "Arquivos de upload excedem o limite de " +
                        config.LIMITE_UPLOAD_MB +
                        "!",
                    true,
                    0
                );
                return false;
            }
        }
        return true;
    }

    validaTamanhoArquivosEntrada() {
        // Validando tamanho dos arquivos
        if (this.state.filesEntrada.length) {
            var size = 0;
            for (var i = 0; i < this.state.filesEntrada.length; i++) {
                const file = this.state.filesEntrada[i];
                size += file.size;
            }

            if (size > config.LIMITE_UPLOAD_BYTES) {
                this.autoCloseAlert(
                    "Erro",
                    "Arquivos de entrada excedem o limite de " +
                        config.LIMITE_UPLOAD_MB +
                        "!",
                    true,
                    0
                );
                return false;
            }
        }
        return true;
    }

    enviaAnexos = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        // Enviando anexos
        if (self.state.files.length) {
            self.state.files.forEach(file => {
                const data = new FormData();
                data.append("path", file, file.name);
                data.append("descricao", file.name);
                data.append("solicitacao_id", self.props.solicitacao.id);
                axios
                    .post(config.API + config.ANEXOS_QUERY, data, {
                        onUploadProgress: ProgressEvent => {
                            self.setState({
                                loaded:
                                    (ProgressEvent.loaded /
                                        ProgressEvent.total) *
                                    100
                            });
                        },
                        headers: headers
                    })
                    .catch(function(error) {
                        var mensagem = error;
                        console.log("error aqui 1", error);
                        if (error.response) {
                            mensagem = error.response.data;
                            console.log("error aqui 2", error.response.data);
                            console.log("error aqui 3", error.response.status);
                            console.log("error aqui 4", error.response.headers);
                        } else if (error.request) {
                            console.log("error aqui 5", error.request);
                        } else {
                            mensagem = error.message;
                            console.log("error aqui 6", error.message);
                        }
                        console.log("error aqui 7", error.config);

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Erro enviando anexos! Detalhes: " + mensagem
                            );
                        }
                    });
            });
        }
    };

    onPressDevolver = departamento => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (departamento == "") {
            this.props.onEventoErro("Selecione o departamento para devolver!");
            return;
        }

        const postdata = {
            solicitacao_id: self.props.solicitacao.id,
            departamento_id: -1,
            departamento_descricao: departamento,
            analista_departamento: self.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateDepartamento/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // atualizando para etapa 2
                self.atualizaEtapa(2);

                // Disparando evento de sucesso
                if (typeof self.props.onEventoSucesso === "function") {
                    self.props.onEventoSucesso(response.data.descricao);
                }
            })
            .catch(function(error) {
                console.log("error aqui 1", error);
                if (error.response) {
                    console.log("error aqui 2", error.response.data);
                    console.log("error aqui 3", error.response.status);
                    console.log("error aqui 4", error.response.headers);
                } else if (error.request) {
                    console.log("error aqui 5", error.request);
                } else {
                    console.log("error aqui 6", error.message);
                }
                console.log("error aqui 7", error.config);

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível devolver a solicitação!"
                    );
                }
            });
    };

    onDrop = files => {
        this.setState({ files });
    };

    onComentar = () => {
        this.onPressComentario();
    };

    onPressComentario = () => {
        const self = this;

        if (this.validaTamanhoArquivos()) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (!this.state.comentario) {
                this.props.onEventoErro("Preencha o campo Comentário!");
                return;
            }

            // Enviando outros dados do chamado
            const postdata = {
                solicitacao_id: this.props.solicitacao.id,
                comentario: this.state.comentario,
                notificar_por_email: false,
                analista_departamento: this.props.usuario.fullname,
                usuario_email: this.props.usuario.username,
                pessoa_id: null,
                is_solicitante: solicitacoes.comparaEmailCaseInsensitive(
                    this.props.usuario.username,
                    this.props.solicitacao.solicitante.email
                )
                    ? true
                    : false
            };

            //console.log('analista atendimento:', this.props.usuario.fullname);

            axios
                .post(config.API + "comentarios/", postdata, {
                    headers: headers
                })
                .then(function(response) {
                    if (self.state.files.length > 0) {
                        self.enviaAnexos();
                    }

                    if (typeof self.props.onEventoSucesso === "function") {
                        self.props.onEventoSucesso(response.data.descricao);
                    }
                })
                .catch(function(error) {
                    if (typeof self.props.onEventoErro === "function") {
                        self.props.onEventoErro(
                            "Não foi possível encaminhar a solicitação!"
                        );
                    }
                });
        }
    };

    onResolver = () => {
        const self = this;

        if (this.validaTamanhoArquivos()) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (!this.state.comentario) {
                this.props.onEventoErro("Preencha o campo Comentário!");
                return;
            }

            console.log("usuario_email", this.props.usuario.username);

            // Enviando outros dados do chamado
            const postdata = {
                solicitacao_id: this.props.solicitacao.id,
                comentario: this.state.comentario,
                notificar_por_email: false,
                analista_departamento: this.props.usuario.fullname,
                usuario_email: this.props.usuario.username,
                pessoa_id: null,
                is_solicitante: solicitacoes.comparaEmailCaseInsensitive(
                    this.props.usuario.username,
                    this.props.solicitacao.solicitante.email
                )
                    ? true
                    : false,
                resolver: true
            };

            //console.log('analista atendimento:', this.props.usuario.fullname);

            axios
                .post(config.API + "comentarios/", postdata, {
                    headers: headers
                })
                .then(function(response) {
                    if (self.state.files.length > 0) {
                        self.enviaAnexos();
                    }

                    if (typeof self.props.onEventoSucesso === "function") {
                        self.props.onEventoSucesso(response.data.descricao);
                    }
                })
                .catch(function(error) {
                    if (typeof self.props.onEventoErro === "function") {
                        self.props.onEventoErro(
                            "Não foi possível encaminhar a solicitação!"
                        );
                    }
                });
        }
    };

    clearUploadFiles = e => {
        e.preventDefault();
        this.setState({ files: [] });
    };

    clearUploadFilesEntrada = e => {
        e.preventDefault();
        this.setState({ filesEntrada: [] });
    };

    render() {
        //console.log('cheguei aqui no formulário novo de comentario');

        var textoBotao = "Prosseguir";

        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        const filesEntrada = this.state.filesEntrada.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        // Cliente cancelando que já recebeu a máquina depois da fase de e-Ticket na logística
        if (this.props.solicitacao.submotivo.motivo_id === 4) {
            if (this.props.solicitacao.etapa == 4) {
                return (
                    <>
                        {this.state.alert}
                        <div
                            style={{
                                backgroundColor: "rgba(72, 195, 233, 0.5)",
                                paddingTop: 20,
                                paddingBottom: 20,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginBottom: 20
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <p>Número da Nota Fiscal de Entrada</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={
                                                    this.state.numero_nf_entrada
                                                }
                                                onChange={event => {
                                                    if (
                                                        this.state
                                                            .numero_nf_entrada &&
                                                        this.state
                                                            .numero_nf_entrada
                                                            .length == 200
                                                    ) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        numero_nf_entrada:
                                                            event.target.value
                                                    });
                                                }}
                                                onKeyDown={event => {
                                                    console.log(
                                                        "event keycode",
                                                        event.key
                                                    );
                                                    if (
                                                        this.state
                                                            .numero_nf_entrada &&
                                                        this.state
                                                            .numero_nf_entrada
                                                            .length == 200 &&
                                                        event.key == "Backspace"
                                                    ) {
                                                        const numero_nf_entrada = this.state.numero_nf_entrada.substr(
                                                            0,
                                                            this.state
                                                                .numero_nf_entrada
                                                                .length - 1
                                                        );
                                                        this.setState({
                                                            numero_nf_entrada
                                                        });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p>XML da Nota Fiscal de Entrada</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Dropzone
                                                onDrop={this.onDropEntrada}
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                    isDragActive
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getRootProps()}
                                                            className={
                                                                "form-control"
                                                            }
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            {
                                                                <p>
                                                                    <i className="nc-icon nc-cloud-upload-94" />
                                                                    &nbsp;&nbsp;
                                                                    <span
                                                                        style={{
                                                                            borderBottomWidth: 1,
                                                                            borderBottomColor:
                                                                                "black",
                                                                            borderBottomStyle:
                                                                                "dotted"
                                                                        }}
                                                                    >
                                                                        Arraste
                                                                        arquivos
                                                                        aqui
                                                                        para
                                                                        anexar,
                                                                        ou
                                                                        clique
                                                                        para
                                                                        pesquisar
                                                                    </span>
                                                                    {filesEntrada.length >
                                                                        0 && (
                                                                        <FormGroup>
                                                                            <FormText
                                                                                color="default"
                                                                                tag="span"
                                                                            >
                                                                                <label>
                                                                                    Arquivos
                                                                                </label>
                                                                                <ul>
                                                                                    {
                                                                                        filesEntrada
                                                                                    }
                                                                                </ul>
                                                                            </FormText>
                                                                            <a
                                                                                href="#"
                                                                                onClick={
                                                                                    this
                                                                                        .clearUploadFilesEntrada
                                                                                }
                                                                            >
                                                                                Resetar
                                                                                anexos
                                                                            </a>
                                                                        </FormGroup>
                                                                    )}
                                                                </p>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ textAlign: "right" }}>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={
                                            this.onPressEncaminharFaturamento3
                                        }
                                    >
                                        {textoBotao}
                                    </Button>
                                </Col>
                            </Row>

                            <RetornoChamado onPress={this.onPressDevolver} />
                        </div>
                    </>
                );
            }

            return (
                <>
                    {this.state.alert}
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={12}>
                                <p>Comentários:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="textarea"
                                            value={this.state.comentario}
                                            onChange={event => {
                                                //console.log('event comentarios', event.target);
                                                if (
                                                    this.state.comentario &&
                                                    this.state.comentario
                                                        .length == 200
                                                ) {
                                                    return;
                                                }
                                                this.setState({
                                                    comentario:
                                                        event.target.value
                                                });
                                            }}
                                            onKeyDown={event => {
                                                console.log(
                                                    "event keycode",
                                                    event.key
                                                );
                                                if (
                                                    this.state.comentario &&
                                                    this.state.comentario
                                                        .length == 200 &&
                                                    event.key == "Backspace"
                                                ) {
                                                    const comentario = this.state.comentario.substr(
                                                        0,
                                                        this.state.comentario
                                                            .length - 1
                                                    );
                                                    this.setState({
                                                        comentario
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    <p
                                        style={{
                                            fontSize: "10px",
                                            opacity: 0.8
                                        }}
                                    >
                                        Caracteres restantes:{" "}
                                        {this.state.comentario
                                            ? 200 - this.state.comentario.length
                                            : 200}
                                    </p>
                                </Form>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Dropzone onDrop={this.onDrop}>
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive
                                        }) => {
                                            return (
                                                <div
                                                    {...getRootProps()}
                                                    className={"form-control"}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {
                                                        <p>
                                                            <i className="nc-icon nc-cloud-upload-94" />
                                                            &nbsp;&nbsp;
                                                            <span
                                                                style={{
                                                                    borderBottomWidth: 1,
                                                                    borderBottomColor:
                                                                        "black",
                                                                    borderBottomStyle:
                                                                        "dotted"
                                                                }}
                                                            >
                                                                Arraste arquivos
                                                                aqui para
                                                                anexar, ou
                                                                clique para
                                                                pesquisar
                                                            </span>
                                                            {files.length >
                                                                0 && (
                                                                <FormGroup>
                                                                    <FormText
                                                                        color="default"
                                                                        tag="span"
                                                                    >
                                                                        <label>
                                                                            Arquivos
                                                                        </label>
                                                                        <ul>
                                                                            {
                                                                                files
                                                                            }
                                                                        </ul>
                                                                    </FormText>
                                                                    <a
                                                                        href="#"
                                                                        onClick={
                                                                            this
                                                                                .clearUploadFiles
                                                                        }
                                                                    >
                                                                        Resetar
                                                                        anexos
                                                                    </a>
                                                                </FormGroup>
                                                            )}
                                                        </p>
                                                    }
                                                </div>
                                            );
                                        }}
                                    </Dropzone>
                                </FormGroup>
                            </Col>
                        </Row>
                        {(this.props.solicitacao.status.id == 7 ||
                            (this.props.solicitacao.status.id != 7 &&
                                this.props.solicitacao.submotivo.id != 19)) && (
                            <>
                                {!solicitacoes.comparaEmailCaseInsensitive(
                                    this.props.solicitacao.solicitante.email,
                                    this.props.usuario.username
                                ) && (
                                    <Row>
                                        <Col
                                            md={6}
                                            style={{ textAlign: "left" }}
                                        >
                                            <Button
                                                className="btn btn-success"
                                                onClick={this.onResolver}
                                            >
                                                Resolvido
                                            </Button>
                                        </Col>
                                        <Col
                                            md={6}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                className="btn btn-primary"
                                                onClick={this.onComentar}
                                            >
                                                Pendenciar
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                {solicitacoes.comparaEmailCaseInsensitive(
                                    this.props.solicitacao.solicitante.email,
                                    this.props.usuario.username
                                ) && (
                                    <Row>
                                        <Col
                                            md={12}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                className="btn btn-primary"
                                                onClick={this.onComentar}
                                            >
                                                Comentar
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                        {this.props.solicitacao.status.id != 7 &&
                            this.props.solicitacao.submotivo.id == 19 && (
                                <Row>
                                    <Col md={12} style={{ textAlign: "right" }}>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={this.onComentar}
                                        >
                                            {textoBotao}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                    </div>
                </>
            );
        } else {
            // Envio de bobina
            if (this.props.solicitacao.etapa == 2) {
                return (
                    <>
                        {this.state.alert}
                        <div
                            style={{
                                backgroundColor: "rgba(72, 195, 233, 0.5)",
                                paddingTop: 20,
                                paddingBottom: 20,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginBottom: 20
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <p>Número da Nota Fiscal</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.numero_nf}
                                                onChange={event => {
                                                    if (
                                                        this.state.numero_nf &&
                                                        this.state.numero_nf
                                                            .length == 200
                                                    ) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        numero_nf:
                                                            event.target.value
                                                    });
                                                }}
                                                onKeyDown={event => {
                                                    console.log(
                                                        "event keycode",
                                                        event.key
                                                    );
                                                    if (
                                                        this.state.numero_nf &&
                                                        this.state.numero_nf
                                                            .length == 200 &&
                                                        event.key == "Backspace"
                                                    ) {
                                                        const numero_nf = this.state.numero_nf.substr(
                                                            0,
                                                            this.state.numero_nf
                                                                .length - 1
                                                        );
                                                        this.setState({
                                                            numero_nf
                                                        });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p>XML da Nota Fiscal</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Dropzone onDrop={this.onDrop}>
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                    isDragActive
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getRootProps()}
                                                            className={
                                                                "form-control"
                                                            }
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            {
                                                                <p>
                                                                    <i className="nc-icon nc-cloud-upload-94" />
                                                                    &nbsp;&nbsp;
                                                                    <span
                                                                        style={{
                                                                            borderBottomWidth: 1,
                                                                            borderBottomColor:
                                                                                "black",
                                                                            borderBottomStyle:
                                                                                "dotted"
                                                                        }}
                                                                    >
                                                                        Arraste
                                                                        arquivos
                                                                        aqui
                                                                        para
                                                                        anexar,
                                                                        ou
                                                                        clique
                                                                        para
                                                                        pesquisar
                                                                    </span>
                                                                    {files.length >
                                                                        0 && (
                                                                        <FormGroup>
                                                                            <FormText
                                                                                color="default"
                                                                                tag="span"
                                                                            >
                                                                                <label>
                                                                                    Arquivos
                                                                                </label>
                                                                                <ul>
                                                                                    {
                                                                                        files
                                                                                    }
                                                                                </ul>
                                                                            </FormText>
                                                                            <a
                                                                                href="#"
                                                                                onClick={
                                                                                    this
                                                                                        .clearUploadFiles
                                                                                }
                                                                            >
                                                                                Resetar
                                                                                anexos
                                                                            </a>
                                                                        </FormGroup>
                                                                    )}
                                                                </p>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ textAlign: "right" }}>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={
                                            this.onPressEncaminharFaturamento
                                        }
                                    >
                                        {textoBotao}
                                    </Button>
                                </Col>
                            </Row>

                            <RetornoChamado onPress={this.onPressDevolver} />
                        </div>
                    </>
                );
            // Troca de máquina depois que emitiu e-Ticket na logística
            } else if (this.props.solicitacao.etapa == 4) {
                return (
                    <>
                        {this.state.alert}
                        <div
                            style={{
                                backgroundColor: "rgba(72, 195, 233, 0.5)",
                                paddingTop: 20,
                                paddingBottom: 20,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginBottom: 20
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <p>Número da Nota Fiscal de Entrada</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={
                                                    this.state.numero_nf_entrada
                                                }
                                                onChange={event => {
                                                    if (
                                                        this.state
                                                            .numero_nf_entrada &&
                                                        this.state
                                                            .numero_nf_entrada
                                                            .length == 200
                                                    ) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        numero_nf_entrada:
                                                            event.target.value
                                                    });
                                                }}
                                                onKeyDown={event => {
                                                    console.log(
                                                        "event keycode",
                                                        event.key
                                                    );
                                                    if (
                                                        this.state
                                                            .numero_nf_entrada &&
                                                        this.state
                                                            .numero_nf_entrada
                                                            .length == 200 &&
                                                        event.key == "Backspace"
                                                    ) {
                                                        const numero_nf_entrada = this.state.numero_nf_entrada.substr(
                                                            0,
                                                            this.state
                                                                .numero_nf_entrada
                                                                .length - 1
                                                        );
                                                        this.setState({
                                                            numero_nf_entrada
                                                        });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p>XML da Nota Fiscal de Entrada</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Dropzone
                                                onDrop={this.onDropEntrada}
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                    isDragActive
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getRootProps()}
                                                            className={
                                                                "form-control"
                                                            }
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            {
                                                                <p>
                                                                    <i className="nc-icon nc-cloud-upload-94" />
                                                                    &nbsp;&nbsp;
                                                                    <span
                                                                        style={{
                                                                            borderBottomWidth: 1,
                                                                            borderBottomColor:
                                                                                "black",
                                                                            borderBottomStyle:
                                                                                "dotted"
                                                                        }}
                                                                    >
                                                                        Arraste
                                                                        arquivos
                                                                        aqui
                                                                        para
                                                                        anexar,
                                                                        ou
                                                                        clique
                                                                        para
                                                                        pesquisar
                                                                    </span>
                                                                    {filesEntrada.length >
                                                                        0 && (
                                                                        <FormGroup>
                                                                            <FormText
                                                                                color="default"
                                                                                tag="span"
                                                                            >
                                                                                <label>
                                                                                    Arquivos
                                                                                </label>
                                                                                <ul>
                                                                                    {
                                                                                        filesEntrada
                                                                                    }
                                                                                </ul>
                                                                            </FormText>
                                                                            <a
                                                                                href="#"
                                                                                onClick={
                                                                                    this
                                                                                        .clearUploadFilesEntrada
                                                                                }
                                                                            >
                                                                                Resetar
                                                                                anexos
                                                                            </a>
                                                                        </FormGroup>
                                                                    )}
                                                                </p>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p>Número da Nota Fiscal de Saída</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={
                                                    this.state.numero_nf_saida
                                                }
                                                onChange={event => {
                                                    if (
                                                        this.state
                                                            .numero_nf_saida &&
                                                        this.state
                                                            .numero_nf_saida
                                                            .length == 200
                                                    ) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        numero_nf_saida:
                                                            event.target.value
                                                    });
                                                }}
                                                onKeyDown={event => {
                                                    console.log(
                                                        "event keycode",
                                                        event.key
                                                    );
                                                    if (
                                                        this.state
                                                            .numero_nf_saida &&
                                                        this.state
                                                            .numero_nf_saida
                                                            .length == 200 &&
                                                        event.key == "Backspace"
                                                    ) {
                                                        const numero_nf_saida = this.state.numero_nf_saida.substr(
                                                            0,
                                                            this.state
                                                                .numero_nf_saida
                                                                .length - 1
                                                        );
                                                        this.setState({
                                                            numero_nf_saida
                                                        });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p>XML da Nota Fiscal de Saída</p>
                                </Col>
                                <Col md={8}>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <Dropzone onDrop={this.onDrop}>
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                    isDragActive
                                                }) => {
                                                    return (
                                                        <div
                                                            {...getRootProps()}
                                                            className={
                                                                "form-control"
                                                            }
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            {
                                                                <p>
                                                                    <i className="nc-icon nc-cloud-upload-94" />
                                                                    &nbsp;&nbsp;
                                                                    <span
                                                                        style={{
                                                                            borderBottomWidth: 1,
                                                                            borderBottomColor:
                                                                                "black",
                                                                            borderBottomStyle:
                                                                                "dotted"
                                                                        }}
                                                                    >
                                                                        Arraste
                                                                        arquivos
                                                                        aqui
                                                                        para
                                                                        anexar,
                                                                        ou
                                                                        clique
                                                                        para
                                                                        pesquisar
                                                                    </span>
                                                                    {files.length >
                                                                        0 && (
                                                                        <FormGroup>
                                                                            <FormText
                                                                                color="default"
                                                                                tag="span"
                                                                            >
                                                                                <label>
                                                                                    Arquivos
                                                                                </label>
                                                                                <ul>
                                                                                    {
                                                                                        files
                                                                                    }
                                                                                </ul>
                                                                            </FormText>
                                                                            <a
                                                                                href="#"
                                                                                onClick={
                                                                                    this
                                                                                        .clearUploadFiles
                                                                                }
                                                                            >
                                                                                Resetar
                                                                                anexos
                                                                            </a>
                                                                        </FormGroup>
                                                                    )}
                                                                </p>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ textAlign: "right" }}>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={
                                            this.onPressEncaminharFaturamento2
                                        }
                                    >
                                        {textoBotao}
                                    </Button>
                                </Col>
                            </Row>

                            <RetornoChamado onPress={this.onPressDevolver} />
                        </div>
                    </>
                );
            }
        }

        return null;
    }
}
