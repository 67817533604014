import { createStore } from 'redux';
import { Reducers } from '../reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'primary',
    storage, //: storageSession,
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, Reducers);

const Store = createStore(persistedReducer);
const persistor = persistStore(Store);

export { Store, persistor };