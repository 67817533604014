import React from "react";

import axios from 'axios';
import { config } from '../../config';

// reactstrap components
import {
    Label,
    FormGroup,
    Input,
    Row,
    Col,
} from "reactstrap";

// import ImageUpload from "components/CustomUpload/AnnexUpload.jsx";
import InputMask from 'react-input-mask';

export default class SolicitanteForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pessoa_tipo_id: 0,
            tipoPessoa: null,
            documento_cpf_cnpj: ''
        }

        console.log('executando SolicitanteForm');
    }

    onChangeText = (event) => {
        if (typeof (this.props.onChange) === 'function') {
            this.props.onChange(event.target.value, this.state.pessoa_tipo_id);
        }
    }

    componentDidMount() {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': config.AUTH_TOKEN
        }
        console.log("passei aqui 15 ponto e virgula");
        if (this.props.mail_do_solicitante) {

            console.log('mail_do_solicitante', this.props.mail_do_solicitante);
            console.log('config.API', config.API + config.PESSOAS_FILTER_EMAIL + this.props.mail_do_solicitante);
            axios.get(config.API + config.PESSOAS_FILTER_EMAIL + this.props.mail_do_solicitante, { headers: headers })
                .then(result => {
                    console.log('deu certo mail_do_solicitante');
                    var documento = "";
                    var pessoa_tipo = 3;
                    console.log('result.data.results.length', result.data.results.length);
                    if (result.data.results.length) {
                        documento = result.data.results[0].cnpj_cpf;
                        pessoa_tipo = result.data.results[0].pessoa_tipo.id;
                        for (var i = 0; i < result.data.results.length; i++) {
                            if (result.data.results[i].pessoa_tipo.id === 6) {
                                documento = result.data.results[i].cnpj_cpf;
                                pessoa_tipo = result.data.results[i].pessoa_tipo.id;
                            }
                        }
                    }
                    this.setState({ documento_cpf_cnpj: documento, pessoa_tipo_id: pessoa_tipo });
                    if (typeof (this.props.onChange) === 'function') {
                        console.log('deu certo pessoa_tipo', pessoa_tipo);
                        this.props.onChange(documento, pessoa_tipo);
                    }
                })
                .catch(error => {
                    console.log('deu ERRADO mail_do_solicitante');
                    this.setState({ documento_cpf_cnpj: "" });
                    if (typeof (this.props.onChange) === 'function') {
                        this.props.onChange("");
                    }
                });
        }
    }

    getPessoaTipo() {
        var tipo = "PF";
        if (this.state.tipoPessoa) {
            tipo = this.state.tipoPessoa;
        }
        return tipo;
    }

    render() {
        const { mail_do_solicitante } = this.props;
        const { documento_cpf_cnpj } = this.state;
        return (
            <div>

                {!this.props.is_atendimento && (
                    <FormGroup>
                        <label>Email do solicitante</label>
                        <p className="form-control-static">
                            {mail_do_solicitante ? mail_do_solicitante : 'Campo preenchido de forma automática.'}
                        </p>
                    </FormGroup>
                )}

                {this.props.is_atendimento && (
                    <FormGroup>
                        <label>Digita aqui o email do solicitante</label>
                        <Row>
                            <Col md="6">
                                <Input
                                    className="form-control"
                                    placeholder={""}
                                    type="text"
                                    onChange={this.props.onChangeEmail}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}


                {documento_cpf_cnpj == '' && this.props.tipo == 1 && (
                    <>
                        <FormGroup>
                            <div className="form-check-radio">
                                <Label check>
                                    <Input
                                        defaultChecked
                                        defaultValue="PF"
                                        name={"radioTipoPessoaRequerente"}
                                        type="radio"
                                        onChange={(event) => { this.setState({ tipoPessoa: event.target.value }) }}
                                    />
                                    Pessoa Física <span className="form-check-sign" />
                                </Label>
                            </div>
                            <div className="form-check-radio">
                                <Label check>
                                    <Input
                                        defaultValue="PJ"
                                        name={"radioTipoPessoaRequerente"}
                                        type="radio"
                                        onChange={(event) => { this.setState({ tipoPessoa: event.target.value }) }}
                                    />
                                    Pessoa Jurídica <span className="form-check-sign" />
                                </Label>
                            </div>
                        </FormGroup>
                        <label>Digita aqui o seu {this.getPessoaTipo() == "PF" ? "CPF" : "CNPJ"}</label>
                        <FormGroup>
                            <Row>
                                <Col md="6">
                                    <InputMask
                                        className="form-control"
                                        placeholder={this.getPessoaTipo() == "PF" ? "CPF" : "CNPJ"}
                                        type="text"
                                        onChange={this.onChangeText}
                                        mask={this.getPessoaTipo() == "PF" ? "999.999.999-99" : "99.999.999/9999-99"}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </>
                )}

                {!this.props.is_atendimento && (documento_cpf_cnpj != '' || this.props.tipo != 1) && (
                    <FormGroup>
                        <label>CPF/CNPJ do solicitante</label>
                        <p className="form-control-static">
                            {documento_cpf_cnpj ? documento_cpf_cnpj : 'Campo preenchido de forma automática.'}
                        </p>
                    </FormGroup>
                )}
            </div>
        )
    }
}
