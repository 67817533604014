import React from "react";

import { connect } from "react-redux";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { config } from '../../config';

class AdminGrupos extends React.Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card style={{ height: this.state.height}}>
                <CardBody id="app">
                  <iframe width="100%" height="100%" src={config.API_AUTH + "admin/auth/group/"} frameBorder={0} allowFullScreen={true}></iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default connect(
  (sessions) => (sessions),
  null
)(AdminGrupos);
