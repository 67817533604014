import React from 'react'
import { render } from 'react-dom'
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import PortalLayout from "layouts/Portal/Portal.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import { Store, persistor } from './store';

const hist = createBrowserHistory();

render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={hist}>
        <Switch>
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Route path="/portal" render={props => <PortalLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>, document.getElementById('root'))
