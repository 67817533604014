import { ADD_SESSION, REMOVE_SESSION } from '../actions/actionTypes';

const initialState = {
  token: '',
  user: null
}

function createSession(state, action) {
    state = {
      token : action.payload.token,
      user: action.payload.user
    };
    return state;
}

function removeSession(state, action) {
  state = {
    token : action.payload.token,
    user: action.payload.user
  };
  //console.log('passei aqui removendo a sessão');
  return state;
}

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
      case ADD_SESSION:
        //console.log('ADD_SESSION');
        return createSession(state, action);     
      case REMOVE_SESSION:
        //console.log('REMOVE_SESSION');
        return removeSession(state, action);
      default:
        //console.log('DEFAULT');
        return state;
  }
}
