import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Input,
    FormText, Label,
    Button
} from "reactstrap";
import axios from 'axios';
import { config } from '../../config';
import Select from "react-select";
import { sendGetRequest } from '../../helpers/request';

export default class FormSuporte extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            comentario: '',
            quantidade_bobinas: '',
            parecerTecnicoOptions: [
                { value: '', label: 'Selecione uma opção...' },
                { value: 'Tamper', label: 'Tamper' },
                { value: 'Problema no leitor do cartão', label: 'Problema no leitor do cartão' },
                { value: 'Terminal intermitente', label: 'Terminal intermitente' },
                { value: 'Problema no carregador', label: 'Problema no carregador' },
                { value: 'Problema na bateria', label: 'Problema na bateria' },
                { value: 'Defeito no chip', label: 'Defeito no chip' },
                { value: 'Problema de ativação/instalação da máquina', label: 'Problema de ativação/instalação da máquina' },
            ],
            parecerTecnico: null,
            encaminharOptions: []
        }
    }

    // Atualizando etapa do chamado
    atualizaEtapa = (etapa) => {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': config.AUTH_TOKEN
        }

        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            etapa,
        }

        axios.post(config.API + 'SolicitacaoUpdateEtapa/', postdata, { headers: headers })
            .then((response) => { });
    }

    onPressEncaminharComComentario = () => {
        const self = this;

        var headers = {
            'Content-Type': 'application/json',
            'Authorization': config.AUTH_TOKEN
        }

        if (typeof (this.props.onEvento) === 'function') {
            this.props.onEvento();
        }

        if (this.state.comentario == '') {
            this.props.onEventoErro('Preencha o comentário!');
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: this.state.isEncaminhar ? 3 : 6,
            analista_departamento: this.props.usuario.fullname
        }

        axios.post(config.API + 'SolicitacaoUpdateStatus/', postdata, { headers: headers })
            .then(function (response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 10,
                    descricao: self.state.comentario,
                    analista_departamento: self.props.usuario.fullname,
                    departamento_id: self.props.solicitacao.departamento.id
                }

                axios.post(config.API + 'campos/', postdata, { headers: headers })
                    .then(function (response) {

                            // Se for motivo 4 (Serviços) verifica se é pra encaminhar
                            if (self.state.isEncaminhar) {
                                //self.state.encaminhar.value

                                console.log('encaminhar.value', self.state.encaminhar.value);

                                const postdata = {
                                    solicitacao_id: self.props.solicitacao.id,
                                    departamento_id: -1,
                                    departamento_descricao: self.state.encaminhar.value,
                                    analista_departamento: self.props.usuario.fullname
                                }

                                axios.post(config.API + 'SolicitacaoUpdateDepartamento/', postdata, { headers: headers })
                                    .then(function (response) {
                                        if (typeof (self.props.onEventoSucesso) === 'function') {
                                            self.props.onEventoSucesso(response.data.descricao);
                                        }
                                    }).catch(function (error) {

                                        console.log('error aqui 1', error);
                                        if (error.response) {
                                            console.log('error aqui 2', error.response.data);
                                            console.log('error aqui 3', error.response.status);
                                            console.log('error aqui 4', error.response.headers);
                                        } else if (error.request) {
                                            console.log('error aqui 5', error.request);
                                        } else {
                                            console.log('error aqui 6', error.message);
                                        }
                                        console.log('error aqui 7', error.config);

                                        if (typeof (self.props.onEventoErro) === 'function') {
                                            self.props.onEventoErro('3 - Não foi possível encaminhar a solicitação!');
                                        }
                                    });
                            } else {
                                if (typeof (self.props.onEventoSucesso) === 'function') {
                                    self.props.onEventoSucesso(response.data.descricao);
                                }
                            }

                    })
                    .catch(function (error) {

                        console.log('error aqui 1', error);
                        if (error.response) {
                            console.log('error aqui 2', error.response.data);
                            console.log('error aqui 3', error.response.status);
                            console.log('error aqui 4', error.response.headers);
                        } else if (error.request) {
                            console.log('error aqui 5', error.request);
                        } else {
                            console.log('error aqui 6', error.message);
                        }
                        console.log('error aqui 7', error.config);

                        if (typeof (self.props.onEventoErro) === 'function') {
                            self.props.onEventoErro('2 - Não foi possível encaminhar a solicitação!');
                        }
                    });

            })
            .catch(function (error) {

                console.log('error aqui 1', error);
                if (error.response) {
                    console.log('error aqui 2', error.response.data);
                    console.log('error aqui 3', error.response.status);
                    console.log('error aqui 4', error.response.headers);
                } else if (error.request) {
                    console.log('error aqui 5', error.request);
                } else {
                    console.log('error aqui 6', error.message);
                }
                console.log('error aqui 7', error.config);

                if (typeof (self.props.onEventoErro) === 'function') {
                    self.props.onEventoErro('1 - Não foi possível encaminhar a solicitação!');
                }
            });
    }

    componentDidMount() {
        const self = this;
            sendGetRequest(config.API + config.MOTIVOS_QUERY + this.props.solicitacao.submotivo.motivo_id).then(result => {
                var data = [];
                // console.log('result motivo 4', result);
                if (result) {
                    const submotivo = result.submotivos.find(f => f.id === this.props.solicitacao.submotivo.id);
                    if (submotivo) {
                        const encaminhos = submotivo.encaminhar.split(',');
                        encaminhos.forEach(m => {
                            if (m !== this.props.solicitacao.departamento.descricao) {
                                data.push({ value: m, label: m });
                            }
                        });
                        self.setState({ encaminharOptions: data });
                    }
                }

            });
    }

    render() {
        var textoBotao = this.state.isEncaminhar ? 'Prosseguir' : 'Fechar';

        return (
            <div style={{ backgroundColor: 'rgba(72, 195, 233, 0.5)', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
                <Row>
                    <Col md={12}>
                        <p>Comentário interno:<br /><span style={{ fontSize: '10px', opacity: 0.7 }}>(não visível para o cliente)</span></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form action="#" method="#">
                            <FormGroup>
                                <Input type="textarea"
                                    value={this.state.comentario}
                                    onChange={(event) => {
                                        //console.log('event comentarios', event.target);
                                        if (this.state.comentario && this.state.comentario.length == 200) {
                                            return;
                                        }
                                        this.setState({ comentario: event.target.value })
                                    }}
                                    onKeyDown={(event) => {
                                        console.log('event keycode', event.key);
                                        if (this.state.comentario && this.state.comentario.length == 200 && event.key == 'Backspace') {
                                            const comentario = this.state.comentario.substr(0, this.state.comentario.length - 1);
                                            this.setState({ comentario });
                                        }
                                    }}
                                />


                            </FormGroup>
                            <p style={{ fontSize: '10px', opacity: 0.8 }}>
                                Caracteres restantes: {this.state.comentario ? 200 - this.state.comentario.length : 200}
                            </p>
                        </Form>
                    </Col>
                </Row>
                {this.state.encaminharOptions && this.state.encaminharOptions.length > 0 && (
                    <Row>
                        <Col md={12}>
                            <FormGroup check className="text-left">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={this.state.isEncaminhar}
                                        defaultValue={0}
                                        name={"checkEncaminhar"}
                                        onChange={(event) => {
                                            this.setState({ isEncaminhar: this.state.isEncaminhar ? false : true, submotivoSolicitacao: null })
                                        }}
                                    />
                                    <span className="form-check-sign" />
                                    Encaminhar?
                            </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                {this.state.isEncaminhar && (
                    <Row>
                        <Col md={12}>
                            <Form action="#" method="#">
                                <FormGroup>
                                    <Select
                                        name="encaminhar"
                                        value={this.state.encaminhar}
                                        onChange={value => {
                                            this.setState({ encaminhar: value });
                                        }}
                                        options={this.state.encaminharOptions}
                                        placeholder=""
                                    />
                                </FormGroup>

                            </Form>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={12} style={{ textAlign: 'right' }}>
                        <Button className="btn btn-primary" onClick={this.onPressEncaminharComComentario}>{textoBotao}</Button>
                    </Col>
                </Row>
            </div>
        );

    }
}
