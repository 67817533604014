import axios from 'axios';
import { config } from '../../config';

export const solicitacoes = {

    getHeaders: () => {
        return {
            'Content-Type': 'application/json',
            'Authorization': config.AUTH_TOKEN
        }
    },

    getSolicitacao: async (id) => {
        return axios.get(config.API + config.SOLICITACOES_BY_ID + id, { headers: solicitacoes.getHeaders() })
            .then(result => {
                //console.log('result', result.data);
                return result.data;
            })
            .catch(error => {
                console.log('error getSolicitacao', error);
                return { error: error.message }
            });
    },

    getSubmotivos: async () => {
        return axios.get(config.API + config.MOTIVOS_QUERY, { headers: solicitacoes.getHeaders() })
            .then(result => {
                //console.log('result', result.data);
                var submotivoOptions = [];
                result.data.results.forEach((m) => {
                    m.submotivos.forEach((sm) => {
                        submotivoOptions.push({ value: sm.id, label: sm.descricao, foreignkey: m.id });
                    });
                });
                return submotivoOptions;
            })
            .catch(error => {
                console.log('error getSubmotivos', error);
                return { error: error.message }
            });
    },

    comparaEmailCaseInsensitive: (email1, email2) => {
        return email1.toLowerCase() == email2.toLowerCase();
    }
}
