import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Container,
    Col,
    Row
} from "reactstrap";

import logo_normal from "assets/img/V3_G.png";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSession } from "../../actions";
import { isAuthenticated } from "../../helpers/auth/auth";

class ResetPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: isAuthenticated(this.props.token),
            alert: null,
            loading: false,
            fields: {
                email: ""
            },
            fields_state: {
                email: ""
            },
            redirect_auth: false
        };
    }

    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    autoCloseAlertSuccess = (title, message, showButton) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlertSuccess()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    hideAlertSuccess = () => {
        this.setState({
            alert: null,
            redirect_auth: true
        });
    };

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    async btn_send(props) {
        const { fields, fields_state } = this.state;
        this.setState({ loading: true });
        let is_valid = true;
        let title = "Redefinição de senha";
        let message = "";
        let state = "has-success";

        if (fields.email.length < 1) {
            is_valid = false;
            message = "Campo obrigatório";
            state = "has-danger";
        } else if (!this.verifyEmail(fields.email)) {
            is_valid = false;
            message = "Email inválido";
            state = "has-danger";
        } else {
            await this.validateEmail()
                .catch(e => {
                    const data = e.response.data;
                    if (!data.is_valid) {
                        is_valid = false;
                        message = data.erro;
                    }
                });
        }

        if (is_valid) {
            await this.sendTicket()
                .then(() => {
                    this.autoCloseAlertSuccess(
                        "Redefinição de senha",
                        "Um email foi enviado com o link para redefinir sua senha"
                    );
                })
                .catch(err => {
                    this.autoCloseAlert(
                        "Redefinição de senha",
                        err.response.data.erro
                    );
                });
        } else {
            this.setState({ ...(fields_state.email = state) });
            this.autoCloseAlert(title, message);
        }

        this.setState({
            loading: false
        });
    }

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };

    validateEmail = async () => {
        const { fields } = this.state;
        return await axios.post(
            config.API_AUTH + config.VERIFY_EMAIL + fields.email,
            { pass: fields.pass, pass_confirm: fields.pass_confirm },
            {
                auth: {
                    username: config.BEVI_USER,
                    password: config.BEVI_PASS
                }
            }
        );
    };

    sendTicket = async () => {
        const { fields } = this.state;
        return await axios.post(
            config.API_AUTH + config.RESET_TICKET + fields.email,
            { pass: fields.pass, pass_confirm: fields.pass_confirm },
            {
                auth: {
                    username: config.BEVI_USER,
                    password: config.BEVI_PASS
                }
            }
        );
    };

    handleChange = () => ({ target: { name, value } }) => {
        const { fields, fields_state } = this.state;
        const state = this.verifyEmail(value) ? "has-success" : "has-danger";

        this.setState({
            fields: {
                ...fields,
                [name]: value
            },
            fields_state: {
                ...fields_state,
                [name]: state
            }
        });
    };

    render() {
        const { fields, fields_state } = this.state;
        if (this.state.logged || this.state.redirect_auth)
            return <Redirect to="/" />;

        return (
            <div className="login-page">
                {this.state.loading && (
                    <>
                        <div
                            style={{
                                backgroundColor: "black",
                                opacity: 0.4,
                                position: "fixed",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 5000
                            }}
                        ></div>
                        <div
                            style={{
                                backgroundColor: "white",
                                opacity: 1,
                                width: 150,
                                padding: 17,
                                borderRadius: 5,
                                textAlign: "center",
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                marginLeft: -75,
                                marginTop: -50,
                                overflow: "hidden",
                                zIndex: 5500,
                                display: "block"
                            }}
                        >
                            <Loader
                                type="Oval"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    </>
                )}
                {this.state.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="6">
                            <Form action="" className="form" method="">
                                <Card className="card-login">
                                    <CardHeader>
                                        <CardHeader>
                                            <h3 className="header text-center">
                                                <img
                                                    style={{ maxWidth: 180 }}
                                                    src={logo_normal}
                                                    alt="react-logo-normal"
                                                />
                                                <p>
                                                    Informe seu email de acesso
                                                </p>
                                            </h3>
                                        </CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="12">
                                                <label>E-mail</label>
                                                <FormGroup
                                                    class={fields_state.email}
                                                >
                                                    <Input
                                                        name="email"
                                                        value={fields.email}
                                                        type="email"
                                                        onChange={this.handleChange()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            block
                                            className="btn-round mb-3"
                                            color="warning"
                                            onClick={() => this.btn_send()}
                                        >
                                            Enviar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/Imagem_Portal_Venus_1440x960.jpg")})`
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addSession }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPass);
