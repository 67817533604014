import React from "react";
import { Redirect } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";

import logo from "assets/img/V3_GG.png";
import logo_normal from "assets/img/V3_G.png";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSession } from "../../actions";
import {
    getToken,
    tokenRefresh,
    isAuthenticated
} from "../../helpers/auth/auth";

/* import { sessions } from "../../reducers/sessions";
import { addSession } from "../../actions/Sessions";
*/

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: isAuthenticated(this.props.token),
            user: "",
            pass: "",
            alert: null,
            loading: false,
            forgot_pass: false
        };
        //console.log('state do login', this.state)
    }

    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo != 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    login(props) {
        this.setState({ loading: true });
        let user = {
            username: this.state.user,
            password: this.state.pass
        };
        getToken(props, user).then(result => {
            this.setState({ loading: false });
            //console.log('passei aqui no login, sabe...')
            if (!result) {
                //console.log('login invalido!')
                this.autoCloseAlert(
                    "Login inválido!",
                    "Verifique as informações e tente novamente.",
                    true,
                    0
                );
            } else {
                //console.log('sucesso no login!')
            }
        });
    }

    token() {
        //console.log('passei no refresh do token', this.props)
        tokenRefresh(this.props);
    }

    render() {
        if (this.state.forgot_pass) {
            return <Redirect to="/auth/confirm-email" />;
        }
        if (this.state.logged) {
            if (
                this.props.user.groups.find(
                    f =>
                        f.name.toLowerCase() === "agente" ||
                        f.name.toLowerCase() === "executivo" ||
                        f.name.toLowerCase() === "gerente comercial" ||
                        f.name.toLowerCase() === "regional" ||
                        f.name.toLowerCase() === "vendedor"
                ) !== undefined
            ) {
                return <Redirect to="/comercial/dashboard" />;
            } else if (
                this.props.user.groups.find(
                    f =>
                        f.name.toLowerCase() === "administração" ||
                        f.name.toLowerCase() === "atendimento" ||
                        f.name.toLowerCase() === "cadastro" ||
                        f.name.toLowerCase() === "faturamento" ||
                        f.name.toLowerCase() === "financeiro" ||
                        f.name.toLowerCase() === "logística" ||
                        f.name.toLowerCase() === "suporte"
                ) !== undefined
            ) {
                return <Redirect to="/admin/dashboard" />;
            } else if (
                this.props.user.groups.find(
                    f => f.name.toLowerCase() === "cliente"
                ) !== undefined
            ) {
                return <Redirect to="/portal/dashboard" />;
            }
            return <Redirect to="/auth/unauthorized" />;
        }
        return (
            <div className="login-page">
                {this.state.loading && (
                    <>
                        <div
                            style={{
                                backgroundColor: "black",
                                opacity: 0.4,
                                position: "fixed",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 5000
                            }}
                        />
                        <div
                            style={{
                                backgroundColor: "white",
                                opacity: 1,
                                width: 150,
                                padding: 17,
                                borderRadius: 5,
                                textAlign: "center",
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                marginLeft: -75,
                                marginTop: -50,
                                overflow: "hidden",
                                zIndex: 5500,
                                display: "block"
                            }}
                        >

                                <img
                                    src={require("../../assets/img/loading.svg")}
                                />

                        </div>
                    </>
                )}

                {this.state.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <Form action="" className="form" method="">
                                <Card className="card-login">
                                    <CardHeader>
                                        <CardHeader>
                                            <h3 className="header text-center">
                                                <img
                                                    style={{ maxWidth: 180 }}
                                                    src={logo_normal}
                                                    alt="react-logo-normal"
                                                />
                                                <p>VENUS</p>
                                            </h3>
                                        </CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-single-02" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Login"
                                                type="text"
                                                onChange={event => {
                                                    this.setState({
                                                        user: event.target.value
                                                    });
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-key-25" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Senha"
                                                type="password"
                                                autoComplete="off"
                                                onChange={event => {
                                                    this.setState({
                                                        pass: event.target.value
                                                    });
                                                }}
                                                onKeyPress={event => {
                                                    console.log(
                                                        "event.key",
                                                        event.key
                                                    );
                                                    if (event.key == "Enter") {
                                                        this.login(this.props);
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                    <CardFooter>
                                        <p>
                                            <a
                                                href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        forgot_pass: true
                                                    });
                                                }}
                                            >
                                                Esqueci minha senha
                                            </a>
                                        </p>
                                        <Button
                                            block
                                            className="btn-round mb-3"
                                            color="warning"
                                            href="#"
                                            onClick={e =>
                                                this.login(this.props)
                                            }
                                        >
                                            Entrar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/Imagem_Portal_Venus_1440x960.jpg")})`
                    }}
                />
            </div>
        );
    }
}

//export default Login;

/* export default connect(
  (sessions) => (sessions),
  { addSession }
)(Login) */

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addSession }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
