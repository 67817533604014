import React from "react";
import classnames from "classnames";


import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container
} from "reactstrap";
import { Redirect } from "react-router-dom";

import { bindActionCreators } from 'redux';
import { logout, isAuthenticated, getAuthenticatedUser } from "../../helpers/auth/auth";
import { connect } from 'react-redux';
import { delSession } from '../../actions';

/* 
import { addSession, removeSession } from "../../actions/Sessions";
import { sessions } from "../../reducers/sessions"; */

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        //console.log('PROPS BAR', this.props)
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent",
            logged: isAuthenticated(this.props.token),
            usuario: this.props.user
            //getAuthenticatedUser(this.props.token)
        };

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
        //console.log('navbar did mount', this.props.history);
        /* if (this.props.token == '') {
            this.props.history.push('/auth/login');
        } */
    }

    componentDidUpdate(e) {
        if (
            window.outerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
        //console.log('navbar did update', this.props.history);
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };

    // this function opens and closes the sidebar on small devices
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
    };

    // this function opens and closes the collapse on small devices
    // it also adds navbar-transparent class to the navbar when closed
    // ad bg-white when opened
    toggleCollapse = () => {
        let newState = {
            collapseOpen: !this.state.collapseOpen
        };
        if (!this.state.collapseOpen) {
            newState["color"] = "bg-white";
        } else {
            newState["color"] = "navbar-transparent";
        }
        this.setState(newState);
    };

    logout = () => {
        this.props.delSession();
        //console.log('AdminNavbar props', this.props);
        //this.props.history.push('/auth/login');
        window.location.reload();
    }

    componentWillReceiveProps(props, state) {
        //if (!props.token || props.token == '')
        //    props.history.push('/auth/login');
    }
    

    render() {
        if (!this.state.logged) return <Redirect to="/auth/login" />
        //console.log('usuario', this.state.usuario);
        return (
            <>
                <Navbar
                    className={classnames("navbar-absolute fixed-top", this.state.color)}
                    expand="lg"
                >
                    <Container fluid>
                        <div className="navbar-wrapper">
                            <div className="navbar-minimize">
                                <Button
                                    className="btn-icon btn-round"
                                    color="default"
                                    id="minimizeSidebar"
                                    onClick={this.props.handleMiniClick}
                                >
                                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                                </Button>
                            </div>
                            <div
                                className={classnames("navbar-toggle", {
                                    toggled: this.state.sidebarOpen
                                })}
                            >
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={this.toggleSidebar}
                                >
                                    <span className="navbar-toggler-bar bar1" />
                                    <span className="navbar-toggler-bar bar2" />
                                    <span className="navbar-toggler-bar bar3" />
                                </button>
                            </div>
                        </div>
                        <button
                            aria-controls="navigation-index"
                            aria-expanded={this.state.collapseOpen}
                            aria-label="Toggle navigation"
                            className="navbar-toggler"
                            // data-target="#navigation"
                            data-toggle="collapse"
                            type="button"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                        </button>
                        <div>
                            {this.state.usuario.groups.length ? 'Departamento: ' + this.state.usuario.groups[0].name : ''}
                        </div>
                        <Collapse
                            className="justify-content-end"
                            navbar
                            isOpen={this.state.collapseOpen}
                        >
                            <Nav navbar>
                                <NavItem>
                                </NavItem>
                                <UncontrolledDropdown className="btn-rotate" nav>
                                    <DropdownToggle
                                        aria-haspopup={true}
                                        caret
                                        color="default"
                                        data-toggle="dropdown"
                                        id="navbarDropdownMenuLink"
                                        nav
                                    >
                                        <p style={{ marginRight: 10 }}>Olá {this.state.usuario.fullname}!</p>
                                        <i className="nc-icon nc-single-02" />
                                        <p>
                                            <span className="d-lg-none d-md-block">Usuário</span>
                                        </p>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                                        {/* <DropdownItem
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                        >
                                        Perfil
                                        </DropdownItem> */}
                                        <DropdownItem
                                            href="#"
                                            onClick={() => this.logout()}
                                        >
                                            Sair
                                        </DropdownItem>

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}


/* export default connect(
    (sessions) => (sessions),
    { addSession, removeSession }
)(AdminNavbar) */


const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

const mapDispatchToProps = dispatch => 
  bindActionCreators({ delSession }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
