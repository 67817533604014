import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Button
} from "reactstrap";
import Select from "react-select";


export default class RetornoChamado extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            devolver: '',
            devolverOptions: [
                { label: 'Cadastro', value: 'Cadastro' },
                { label: 'Suporte', value: 'Suporte' },
                { label: 'Financeiro', value: 'Financeiro' }
            ]
        }
    }

    onPress = () => {
        if (typeof (this.props.onPress) === 'function') {
            console.log('target value', this.state.devolver.value)
            var devolver = this.state.devolver == '' ? '' : this.state.devolver.value;
            this.props.onPress(devolver);
        }
    }

    render() {
        return(
            <>
                <Row>
                    <Col md={12}>
                        <p>&nbsp;</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>Retorno de chamado errado</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form action="#" method="#">
                            <FormGroup>
                                <Select
                                    name="devolver"
                                    value={this.state.devolver}
                                    onChange={value => {
                                        this.setState({ devolver: value });
                                    }}
                                    options={this.state.devolverOptions}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'right' }}>
                        <Button className="btn btn-danger" onClick={this.onPress}>Devolver</Button>
                    </Col>
                </Row>
            </>
        );
    }
}
