import { ADD_SESSION, REMOVE_SESSION } from './actionTypes';

export const addSession = (token, user) => ({
  type: ADD_SESSION,
    payload: { token, user }
})

export const delSession = () => ({
  type: REMOVE_SESSION,
    payload: { token: null, user: null}
})
