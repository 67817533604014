import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import axios from 'axios';
import { config } from '../../config';
import moment from "moment";
// import FormSolicitacao from './FormSolicitacao';
import FormComentario from './FormComentario';
import FormSuporte from './FormSuporte';
import FormFaturamento from './FormFaturamento';
import FormLogistica from './FormLogistica';
import FormCadastro from './FormCadastro';
import { sendGetRequest } from '../../helpers/request';
import { solicitacoes } from '../../helpers/autoatendimento/solicitacoes';

export default class ModalSolicitacao extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            atividadesOptions: [],
            status_atual: this.props.solicitacao.status.descricao,
            status_cor: this.props.solicitacao.status.cor,
            comentarios: [],
            cliente: null,
            endereco: null,
            arquivos: [],
            campos: []
        }

    }

    componentDidMount() {
        const self = this;
        sendGetRequest(config.API + config.COMENTARIOS_FILTER_SOLICITACAO + this.props.solicitacao.id).then(result => {
            if (result && result.results.length) {
                //console.log('results', result.results);
                self.setState({ comentarios: result.results });
            }
            sendGetRequest(config.API + config.ANEXOS_FILTER_SOLICITACAO + self.props.solicitacao.id).then(result => {
                if (result && result.results.length) {
                    //console.log('results', result.results);
                    self.setState({ arquivos: result.results });
                }
                sendGetRequest(config.API + config.CAMPOS_FILTER_SOLICITACAO + self.props.solicitacao.id).then(result => {
                    if (result && result.results.length) {
                        //console.log('results', result.results);
                        self.setState({ campos: result.results });
                    }
                    sendGetRequest(config.API + config.ATIVIDADES_FILTER_SOLICITACAO + self.props.solicitacao.id).then(result => {
                        if (result && result.results.length) {
                            //console.log('results', result.results);
                            self.setState({ atividadesOptions: result.results });
                        }
                        sendGetRequest(config.API + config.CLIENTE_POR_DOCUMENTO + self.props.solicitacao.requerente.cnpj_cpf).then(result => {
                            self.setState({ cliente: result });
                            sendGetRequest(config.API + config.ENDERECO_POR_DOCUMENTO + self.props.solicitacao.requerente.cnpj_cpf).then(result => {
                                self.setState({ endereco: result });
                                sendGetRequest(config.API + config.DADOS_BANCARIOS_POR_DOCUMENTO + self.props.solicitacao.requerente.cnpj_cpf).then(result => {
                                    self.setState({ dados_bancarios: result });
                                    sendGetRequest(config.API + config.DADOS_PESSOAIS_POR_DOCUMENTO + self.props.solicitacao.requerente.cnpj_cpf).then(result => {
                                        self.setState({ dados_pessoais: result });
                                        sendGetRequest(config.API + config.PEDIDOS_POR_DOCUMENTO + self.props.solicitacao.requerente.cnpj_cpf).then(result => {
                                            self.setState({ pedidos: result, loading: false });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    render() {
        const { isOpen, toggle, className, numero_solicitacao,
            data_abertura, submotivo_motivo, submotivo_descricao,
            requerente_cnpj_cpf, requerente_nome, detalhamento } = this.props;
        //console.log('quantidade_bobinas', this.props.solicitacao.quantidade_bobinas);

        console.log('departamento', this.props.solicitacao.departamento);
        console.log('status', this.props.solicitacao.status);
        console.log('username', this.props.usuario.username);
        console.log('solicitante.email', this.props.solicitacao.solicitante.email);
        return (
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
                <ModalHeader>Protocolo #{numero_solicitacao}</ModalHeader>
                <ModalBody>
                    <div className="form-horizontal">
                        <Row>
                            <Col md={4}>
                                <h6>Status</h6>
                            </Col>
                            <Col md={8}>
                                <p className={'text-' + this.state.status_cor} >{this.state.status_atual}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Depto.</h6>
                            </Col>
                            <Col md={4}>
                                <p>{this.props.solicitacao.departamento.descricao}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Data</h6>
                            </Col>
                            <Col md={8}>
                                <p>{moment(data_abertura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Categoria</h6>
                            </Col>
                            <Col md={8}>
                                <p>{submotivo_motivo}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Motivo</h6>
                            </Col>
                            <Col md={8}>
                                <p>{submotivo_descricao}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h5>Solicitante</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Nome</h6>
                            </Col>
                            <Col md={8}>
                                <p>{this.props.solicitacao.solicitante.nome}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>CPF/CNPJ</h6>
                            </Col>
                            <Col md={8}>
                                <p>{this.props.solicitacao.solicitante.cnpj_cpf}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>E-mail</h6>
                            </Col>
                            <Col md={8}>
                                <p>{this.props.solicitacao.solicitante.email}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h5>Requerente</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>Nome</h6>
                            </Col>
                            <Col md={8}>
                                <p>{requerente_nome}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>CPF/CNPJ</h6>
                            </Col>
                            <Col md={8}>
                                <p>{requerente_cnpj_cpf}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <h6>E-mail</h6>
                            </Col>
                            <Col md={8}>
                                <p>{this.props.solicitacao.requerente.email}</p>
                            </Col>
                        </Row>

                        {this.state.cliente && (
                            <>
                                <Row>
                                    <Col md={4}>
                                        <h6>IE</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{this.state.cliente ? this.state.cliente.inscricao_estadual : ''}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Data da Proposta</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{this.state.cliente ? moment(this.state.cliente.created).format('DD/MM/YYYY HH:mm:ss') : ''}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Tipo de pessoa</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{this.state.cliente ? this.state.cliente.tipo_pessoa : ''}</p>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {this.state.endereco && (
                            <Row>
                                <Col md={4}>
                                    <h6>Endereço</h6>
                                </Col>
                                <Col md={8}>
                                    <p>{this.state.endereco ? this.state.endereco.endereco : ''}
                                        {this.state.endereco ? ', ' + this.state.endereco.numero : ''}
                                        {this.state.endereco ? this.state.endereco.complemento ? ' - ' + this.state.endereco.complemento : '' : ''}<br />
                                        {this.state.endereco ? this.state.endereco.bairro : ''}<br />
                                        {this.state.endereco ? this.state.endereco.cep : ''}<br />
                                        {this.state.endereco ? this.state.endereco.cidade : ''}
                                        {this.state.endereco ? '/' + this.state.endereco.estado : ''}
                                    </p>
                                </Col>
                            </Row>
                        )}

                        {this.state.dados_bancarios && (
                            <Row>
                                <Col md={4}>
                                    <h6>Dados Bancários</h6>
                                </Col>
                                <Col md={8}>
                                    <p>
                                        Banco: {this.state.dados_bancarios.bco}<br />
                                        Agência: {this.state.dados_bancarios.agencia}<br />
                                        Conta: {this.state.dados_bancarios.conta}-{this.state.dados_bancarios.digito}
                                    </p>
                                </Col>
                            </Row>
                        )}

                        {this.state.dados_pessoais && (
                            <Row>
                                <Col md={4}>
                                    <h6>Dados Cadastrais</h6>
                                </Col>
                                <Col md={8}>
                                    <p>
                                        Telefone1: {this.state.dados_pessoais.tel1}<br />
                                        Telefone2: {this.state.dados_pessoais.tel2}
                                    </p>
                                </Col>
                            </Row>
                        )}

                        {this.state.pedidos && (
                            <>
                                <Row>
                                    <Col md={4}>
                                        <h6>Dados do Pedido</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>
                                            Máquina: {this.state.pedidos.maquina}<br />
                                            {/* Quantidade: {this.state.pedidos.quantidade}<br />
                                        Operadora: {this.state.pedidos.operadora} */}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Forma de pagamento</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>
                                            {this.state.pedidos.forma_pagto} em {this.state.pedidos.condicao_pagamento}<br />
                                            {this.state.pedidos.forma_pagto === 'Crédito' && (
                                                <>NSU: {this.state.pedidos.cod_nsu}</>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {detalhamento != '' && (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <h5>Detalhamento</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <p>{detalhamento}</p>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {this.state.campos.length > 0 && (
                            <Row>
                                <Col md={12}>
                                    <h5>Campos</h5>
                                </Col>
                            </Row>
                        )}
                        {this.state.campos.map(item => {
                            if (item.tipo.id == 10 && (solicitacoes.comparaEmailCaseInsensitive(this.props.usuario.username, this.props.solicitacao.solicitante.email))) {
                                return null;
                            }
                            return (
                                <Row>
                                    <Col md={4}>
                                        <h6>{item.tipo.descricao}</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{item.descricao}<br />
                                            <span style={{ fontSize: '10px', opacity: 0.7 }}>{moment(item.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}</span>
                                        </p>
                                    </Col>
                                </Row>
                            );
                        })}


                        {this.state.comentarios.length > 0 && (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <h5>Comentários</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Card>
                                            <CardBody>
                                                <ul className="timeline timeline-simple">
                                                    {this.state.comentarios.map(item => {
                                                        return (
                                                            <li >
                                                                {item.pessoa ? item.pessoa.nome : 'Indefinido'} - {item.comentario} <br />
                                                                {moment(item.data_comentario).format('DD/MM/YYYY HH:mm:ss')}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {this.state.arquivos.length > 0 && (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <h5>Arquivos</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Card>
                                            <CardBody>
                                                <ul className="timeline timeline-simple">
                                                    {this.state.arquivos.map(item => {
                                                        return (
                                                            <li >
                                                                <a href={item.path} target="_blank">{item.descricao ? item.descricao : 'Indefinido'}</a> <br />
                                                                {moment(item.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {!this.props.read_only && (
                            <>
                                {/* Aqui vai o form */}
                                {(
                                    (solicitacoes.comparaEmailCaseInsensitive(this.props.usuario.username, this.props.solicitacao.solicitante.email) && [2, 5].includes(this.props.solicitacao.status.id))
                                    || ([1, 3, 4].includes(this.props.solicitacao.departamento.id) && [1, 3, 4, 7].includes(this.props.solicitacao.status.id)
                                        && !solicitacoes.comparaEmailCaseInsensitive(this.props.usuario.username, this.props.solicitacao.solicitante.email))
                                ) && (
                                        <FormComentario
                                            departamento={this.props.solicitacao.departamento.descricao}
                                            solicitacao_id={this.props.solicitacao.id}
                                            analista_departamento={this.props.usuario.fullname}
                                            onEvento={() => this.props.onEvento()}
                                            onEventoSucesso={(msg) => this.props.onEventoSucesso(msg)}
                                            onEventoErro={(msg) => this.props.onEventoErro(msg)}
                                            solicitacao={this.props.solicitacao}
                                            usuario={this.props.usuario}
                                            fechamento={this.props.solicitacao.status.id == 5}
                                        />
                                    )}
                                {/* Aqui vai o form */}

                                {/* Aqui vai o form */}
                                {(([1, 3, 4, 7].includes(this.props.solicitacao.status.id)
                                    && !solicitacoes.comparaEmailCaseInsensitive(this.props.usuario.username, this.props.solicitacao.solicitante.email))) && (
                                        <FormSuporte
                                            departamento={this.props.solicitacao.departamento.descricao}
                                            solicitacao_id={this.props.solicitacao.id}
                                            analista_departamento={this.props.usuario.fullname}
                                            onEvento={() => this.props.onEvento()}
                                            onEventoSucesso={(msg) => this.props.onEventoSucesso(msg)}
                                            onEventoErro={(msg) => this.props.onEventoErro(msg)}
                                            solicitacao={this.props.solicitacao}
                                            usuario={this.props.usuario}
                                        />
                                    )}
                                {/* Aqui vai o form */}
                            </>
                        )}

                        <Row>
                            <Col md={12}>
                                <h5>Atividades</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="card-timeline card-plain">
                                    <CardBody>
                                        <ul className="timeline timeline-simple">
                                            {this.state.atividadesOptions.map(item => {
                                                //console.log('acao.cor', item.acao.cor);
                                                return (
                                                    <li className="timeline-inverted">
                                                        <div className={"timeline-badge " + (item.acao.cor ? item.acao.cor : 'danger')}>
                                                            <i className={"nc-icon " + (item.acao.icon ? item.acao.icon : 'nc-single-copy-04')} />
                                                        </div>
                                                        <div className="timeline-panel">
                                                            <div className="timeline-body">
                                                                <p>{item.descricao}</p>
                                                            </div>
                                                            <h6>
                                                                <i className="ti-time" />
                                                                {moment(item.data_atividade, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                            </h6>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle} style={{ marginRight: '20px' }}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
