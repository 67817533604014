import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Container,
    Col,
    Row
} from "reactstrap";

import logo_normal from "assets/img/V3_G.png";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSession } from "../../actions";
import {
    tokenRefresh,
    isAuthenticated
} from "../../helpers/auth/auth";

/* import { sessions } from "../../reducers/sessions";
import { addSession } from "../../actions/Sessions";
*/

class ResetPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: isAuthenticated(this.props.token),
            alert: null,
            loading: false,
            fields: {
                pass: "",
                pass_confirm: ""
            },
            fields_state: {
                pass: "",
                pass_confirm: ""
            },
            redirect_auth: false
        };
        //console.log('state do login', this.state)
    }

    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    autoCloseAlertSuccess = (title, message, showButton) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlertSuccess()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    autoCloseAlertFail = (title, message, showButton) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlertFail()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    hideAlertSuccess = () => {
        this.setState({
            alert: null,
            redirect_auth: true
        });
    };

    hideAlertFail = () => {
        this.setState({
            alert: null,
            redirect_auth: true
        });
    };

    async componentDidMount() {
        document.body.classList.toggle("login-page");
        await this.verifyToken();
    }

    verifyToken = async() => {
        const token = String(this.props.location.pathname).split('/')[3]
        await axios.post(config.API_AUTH + config.RESET_TOKEN_VERIFY + token, {}, {auth: { username: config.BEVI_USER, password: config.BEVI_PASS }})
            .catch(e => {
                const data = e.response.data;
                console.log(data);
                this.autoCloseAlertFail("Redefinição de senha", data.erro);
            });
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    async btn_reset(props) {
        const { fields, fields_state } = this.state;
        this.setState({ loading: true });
        let is_valid = true;
        let title = "Redefinição de senha";
        let message = "";

        if (fields.pass.length < 1 || fields.pass_confirm.length < 1){
            is_valid = false;
            message = "Campos Obrigatórios";
        }else if (fields.pass !== fields.pass_confirm){
            is_valid = false;
            message = "Senhas divergentes";
        }else {

        }

        if (is_valid){
            await this.send_reset()
            .then(() => {
                this.autoCloseAlertSuccess("Redefinição de senha", "Redefinição realizada com sucesso");
            }).catch(e => {
                const data = e.response.data;
                this.autoCloseAlert("Redefinição de senha", data.erro);
            });
        }else {
            this.autoCloseAlert(title, message);
        }

        this.setState({
            loading: false
        });
    }

    send_reset = async() => {
        const { fields } = this.state;
        const token = String(this.props.location.pathname).split('/')[3]
        return await axios.post(config.API_AUTH + config.RESET_PASS + token,
            { pass: fields.pass, pass_confirm: fields.pass_confirm },
            {auth: { username: config.BEVI_USER, password: config.BEVI_PASS }});
    }

    token() {
        //console.log('passei no refresh do token', this.props)
        tokenRefresh(this.props);
    }

    handleChange = () => ({ target: { name, value }}) => {
        const { fields, fields_state } = this.state;
        let state = "has-success";
        switch (name) {
            case "pass_confirm":
                state = String(value).length > 0 ? value == fields.pass ? "has-success" : "has-danger" : "has-danger";
                break;
            default:
                state = String(value).length > 0 ? "has-success" : "has-danger";
                break;
        }
        this.setState({
            fields : {
                ...fields,
                [name]: value
            },
            fields_state : {
                ...fields_state,
                [name]: state
            }
        });
    }

    render() {
        const { fields, fields_state } = this.state;
        if (this.state.logged || this.state.redirect_auth) return <Redirect to="/" />;

        return (
            <div className="login-page">
                {this.state.loading && (
                    <>
                        <div
                            style={{
                                backgroundColor: "black",
                                opacity: 0.4,
                                position: "fixed",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 5000
                            }}
                        ></div>
                        <div
                            style={{
                                backgroundColor: "white",
                                opacity: 1,
                                width: 150,
                                padding: 17,
                                borderRadius: 5,
                                textAlign: "center",
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                marginLeft: -75,
                                marginTop: -50,
                                overflow: "hidden",
                                zIndex: 5500,
                                display: "block"
                            }}
                        >
                            <Loader
                                type="Oval"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    </>
                )}
                {this.state.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="6">
                            <Form action="" className="form" method="">
                                <Card className="card-login">
                                    <CardHeader>
                                        <CardHeader>
                                            <h3 className="header text-center">
                                                <img
                                                    style={{ maxWidth: 180 }}
                                                    src={logo_normal}
                                                    alt="react-logo-normal"
                                                />
                                                <p>Altere sua senha</p>
                                            </h3>
                                        </CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <label>Nova senha</label>
                                                <FormGroup class={fields_state.pass}>
                                                    <Input
                                                        name="pass"
                                                        value={fields.pass}
                                                        type="password"
                                                        onChange={this.handleChange()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <label>Confirmação de senha</label>
                                                <FormGroup class={fields_state.pass_confirm}>
                                                    <Input
                                                        name="pass_confirm"
                                                        value={fields.pass_confirm}
                                                        type="password"
                                                        onChange={this.handleChange()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            block
                                            className="btn-round mb-3"
                                            color="warning"
                                            onClick={() =>
                                                this.btn_reset()
                                            }
                                        >
                                            Salvar
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/Imagem_Portal_Venus_1440x960.jpg")})`
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addSession }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPass);
