import axios from "axios";
import { config } from "../../config";

export function getToken(props, user) {
    return axios
        .post(config.API_AUTH + config.AUTH_TOKEN_QUERY, user)
        .then(function(response) {
            var data_user = response.data.user;
            props.addSession(response.data.token, data_user);
            if (data_user.groups) {
                if (
                    data_user.groups.find(
                        f => f.name.toLowerCase() === "cliente"
                    ) !== undefined
                ) {
                    props.history.push("/portal/dashboard");
                }
                else if (
                    data_user.groups.find(
                        f => f.name.toLowerCase() === "agente" ||
                             f.name.toLowerCase() === "executivo" ||
                             f.name.toLowerCase() === "gerente comercial" ||
                             f.name.toLowerCase() === "regional" ||
                             f.name.toLowerCase() === "vendedor"
                    ) !== undefined
                ) {
                    props.history.push("/comercial/dashboard");
                } else {
                    props.history.push("/admin/dashboard");
                }
            } else {
                props.history.push("/auth/login");
            }

            return true;
        })
        .catch(function(error) {
            //console.log('error', error.message);
            //console.log(error.response.data.non_field_errors);
            return false;
        });
    //return true;
}

export function tokenRefresh(props) {
    /* axios.post(config.API_AUTH + config.REFRESH_TOKEN_QUERY, { token: props.token })
        .then(function (response) {
            props.addSession(response.data.token, response.data.user)
        })
        .catch(function (error) {
            console.log('erro', error.response.data.non_field_errors);
        }); */
}

export function isAuthenticated(token) {
    //console.log('isAuthenticated?', token)
    if (!token) return false;
    if (token == "") return false;
    var jwtDecode = require("jwt-decode");
    var ts = Math.round(new Date().getTime() / 1000);
    const token_data = jwtDecode(token);
    if (token_data.exp - ts > 0) {
        return true;
    } else {
        return false;
    }
    //return true;
}

export function getAuthenticatedUser(token) {
    //console.log(token)
    if (!token) return false;
    if (token == "") return null;
    var jwtDecode = require("jwt-decode");
    var ts = Math.round(new Date().getTime() / 1000);
    const token_data = jwtDecode(token);
    //console.log('token_data', token_data);
    if (token_data.exp - ts > 0) {
        return {
            id: token_data.user_id,
            nome: token_data.username,
            email: token_data.email
        };
    } else {
        return null;
    }
}

export function logout(props) {
    props.delSession();
}
