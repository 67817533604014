import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import PortalNavbar from "components/Navbars/PortalNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { connect } from "react-redux";
import routes from "routes.js";

var ps;

class Portal extends React.Component {
    constructor(props) {
        super(props);
        //console.log('constructor props', props)
        this.state = {
            backgroundColor: "black",
            activeColor: "info",
            sidebarMini: false
        };
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel);
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
    }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/portal" && prop.groups == "All") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                if (this.props.user) {
                    if (prop.layout === "/portal") {
                        return (
                            <Route
                                path={prop.layout + prop.path}
                                component={prop.component}
                                key={key}
                            />
                        );
                    } else return null;
                } else {
                    return null;
                }
            }
        });
    };
    handleActiveClick = color => {
        this.setState({ activeColor: color });
    };
    handleBgClick = color => {
        this.setState({ backgroundColor: color });
    };
    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle("sidebar-mini");
    };
    render() {
        //console.log('PROPS Admin', this.props)
        //console.log('ROUTES Admin', routes)
        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    routes={routes.filter(f => f.layout === '/portal')}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <PortalNavbar
                        {...this.props}
                        pagename="Teste"
                        handleMiniClick={this.handleMiniClick}
                    />
                    <Switch>{this.getRoutes(routes)}</Switch>
                    {// we don't want the Footer to be rendered on full screen maps page
                    this.props.location.pathname.indexOf("full-screen-map") !==
                    -1 ? null : (
                        <Footer fluid />
                    )}
                </div>
            </div>
        );
    }
}

//export default Portal;
const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

export default connect(mapStateToProps)(Portal);
