import React from "react";
import {
    Row,
    Col,
    FormGroup,
    Form,
    Input,
    FormText,
    Label,
    Button
} from "reactstrap";
import axios from "axios";
import { config } from "../../config";
import moment from "moment";
import InputMask from "react-input-mask";
import Dropzone from "react-dropzone";
import { solicitacoes } from "../../helpers/autoatendimento/solicitacoes";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default class FormLogistica extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            codigo_hawb: null,
            data_entrega_destino: null,
            data_recebimento: null,
            quem_recebeu: null,
            files: [],
            comentario: null
        };
    }

    // Atualizando etapa do chamado
    atualizaEtapa = etapa => {
        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            etapa
        };

        axios
            .post(config.API + "SolicitacaoUpdateEtapa/", postdata, {
                headers: headers
            })
            .then(response => {});
    };

    onPressEncaminharLogistica = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (!this.state.codigo_hawb) {
            this.props.onEventoErro("Preencha o campo Código de Rastreio!");
            return;
        }

        if (!this.state.data_entrega_destino) {
            this.props.onEventoErro(
                "Preencha o campo Data de Entrega no Destino!"
            );
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 8,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 3,
                    descricao: self.state.codigo_hawb,
                    analista_departamento: self.props.usuario.fullname,
                    data_envio: moment(
                        self.state.data_entrega_destino,
                        "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                };

                axios
                    .post(config.API + "campos/", postdata, {
                        headers: headers
                    })
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(4);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        /* console.log('error aqui 1', error);
                        if (error.response) {
                            console.log('error aqui 2', error.response.data);
                            console.log('error aqui 3', error.response.status);
                            console.log('error aqui 4', error.response.headers);
                        } else if (error.request) {
                            console.log('error aqui 5', error.request);
                        } else {
                            console.log('error aqui 6', error.message);
                        }
                        console.log('error aqui 7', error.config); */

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                /* console.log('error aqui 1', error);
                if (error.response) {
                    console.log('error aqui 2', error.response.data);
                    console.log('error aqui 3', error.response.status);
                    console.log('error aqui 4', error.response.headers);
                } else if (error.request) {
                    console.log('error aqui 5', error.request);
                } else {
                    console.log('error aqui 6', error.message);
                }
                console.log('error aqui 7', error.config); */

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressEncaminharRecebimento = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (!this.state.data_recebimento) {
            this.props.onEventoErro("Preencha o campo Data de Recebimento!");
            return;
        }

        if (!this.state.quem_recebeu) {
            this.props.onEventoErro("Preencha o campo Quem Recebeu!");
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 5,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 7,
                    descricao: self.state.quem_recebeu,
                    analista_departamento: self.props.usuario.fullname,
                    data_envio: moment(
                        self.state.data_recebimento,
                        "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                };

                axios
                    .post(config.API + "campos/", postdata, {
                        headers: headers
                    })
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(5);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        /* console.log('error aqui 1', error);
                        if (error.response) {
                            console.log('error aqui 2', error.response.data);
                            console.log('error aqui 3', error.response.status);
                            console.log('error aqui 4', error.response.headers);
                        } else if (error.request) {
                            console.log('error aqui 5', error.request);
                        } else {
                            console.log('error aqui 6', error.message);
                        }
                        console.log('error aqui 7', error.config); */

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                /* console.log('error aqui 1', error);
                if (error.response) {
                    console.log('error aqui 2', error.response.data);
                    console.log('error aqui 3', error.response.status);
                    console.log('error aqui 4', error.response.headers);
                } else if (error.request) {
                    console.log('error aqui 5', error.request);
                } else {
                    console.log('error aqui 6', error.message);
                }
                console.log('error aqui 7', error.config); */

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressEncaminharLogistica2 = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (!this.state.codigo_hawb) {
            this.props.onEventoErro("Preencha o campo Código de Rastreio!");
            return;
        }

        if (!this.state.data_entrega_destino) {
            this.props.onEventoErro(
                "Preencha o campo Data de Entrega no Destino!"
            );
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 8,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 3,
                    descricao: self.state.codigo_hawb,
                    analista_departamento: self.props.usuario.fullname,
                    data_envio: moment(
                        self.state.data_entrega_destino,
                        "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                };

                axios
                    .post(config.API + "campos/", postdata, {
                        headers: headers
                    })
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(6);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressEncaminharRecebimento2 = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (!this.state.data_recebimento) {
            this.props.onEventoErro("Preencha o campo Data de Recebimento!");
            return;
        }

        if (!this.state.quem_recebeu) {
            this.props.onEventoErro("Preencha o campo Quem Recebeu!");
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 5,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 7,
                    descricao: self.state.quem_recebeu,
                    analista_departamento: self.props.usuario.fullname,
                    data_envio: moment(
                        self.state.data_recebimento,
                        "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                };

                axios
                    .post(config.API + "campos/", postdata, {
                        headers: headers
                    })
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(7);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressEncaminharETicket = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        if (!this.state.codigo_hawb) {
            this.props.onEventoErro("Preencha o campo Código do e-Ticket!");
            return;
        }

        if (!this.state.data_entrega_destino) {
            this.props.onEventoErro("Preencha o campo Data de Envio!");
            return;
        }

        // Enviando outros dados do chamado
        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 9,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Enviando outros dados do chamado
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    tipo_id: 11,
                    descricao: self.state.codigo_hawb,
                    analista_departamento: self.props.usuario.fullname,
                    data_envio: moment(
                        self.state.data_entrega_destino,
                        "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                };

                axios
                    .post(config.API + "campos/", postdata, {
                        headers: headers
                    })
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(3);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        /* console.log('error aqui 1', error);
                        if (error.response) {
                            console.log('error aqui 2', error.response.data);
                            console.log('error aqui 3', error.response.status);
                            console.log('error aqui 4', error.response.headers);
                        } else if (error.request) {
                            console.log('error aqui 5', error.request);
                        } else {
                            console.log('error aqui 6', error.message);
                        }
                        console.log('error aqui 7', error.config); */

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                /* console.log('error aqui 1', error);
                if (error.response) {
                    console.log('error aqui 2', error.response.data);
                    console.log('error aqui 3', error.response.status);
                    console.log('error aqui 4', error.response.headers);
                } else if (error.request) {
                    console.log('error aqui 5', error.request);
                } else {
                    console.log('error aqui 6', error.message);
                }
                console.log('error aqui 7', error.config); */

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressETicketExpirado = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 3,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                // Se deu tudo certo, atualiza a etapa
                self.atualizaEtapa(2);

                if (typeof self.props.onEventoSucesso === "function") {
                    self.props.onEventoSucesso(response.data.descricao);
                }
            })
            .catch(function(error) {
                console.log("error aqui 1", error);
                if (error.response) {
                    console.log("error aqui 2", error.response.data);
                    console.log("error aqui 3", error.response.status);
                    console.log("error aqui 4", error.response.headers);
                } else if (error.request) {
                    console.log("error aqui 5", error.request);
                } else {
                    console.log("error aqui 6", error.message);
                }
                console.log("error aqui 7", error.config);

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "2 Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    onPressETicketEnviado = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        if (typeof this.props.onEvento === "function") {
            this.props.onEvento();
        }

        const postdata = {
            solicitacao_id: this.props.solicitacao.id,
            status_id: 3,
            analista_departamento: this.props.usuario.fullname
        };

        axios
            .post(config.API + "SolicitacaoUpdateStatus/", postdata, {
                headers: headers
            })
            .then(function(response) {
                const postdata = {
                    solicitacao_id: self.props.solicitacao.id,
                    departamento_id: 5,
                    analista_departamento: self.props.usuario.fullname
                };

                axios
                    .post(
                        config.API + "SolicitacaoUpdateDepartamento/",
                        postdata,
                        { headers: headers }
                    )
                    .then(function(response) {
                        // Se deu tudo certo, atualiza a etapa
                        self.atualizaEtapa(4);

                        if (typeof self.props.onEventoSucesso === "function") {
                            self.props.onEventoSucesso(response.data.descricao);
                        }
                    })
                    .catch(function(error) {
                        console.log("error aqui 1", error);
                        if (error.response) {
                            console.log("error aqui 2", error.response.data);
                            console.log("error aqui 3", error.response.status);
                            console.log("error aqui 4", error.response.headers);
                        } else if (error.request) {
                            console.log("error aqui 5", error.request);
                        } else {
                            console.log("error aqui 6", error.message);
                        }
                        console.log("error aqui 7", error.config);

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "1 Não foi possível encaminhar a solicitação!"
                            );
                        }
                    });
            })
            .catch(function(error) {
                console.log("error aqui 1", error);
                if (error.response) {
                    console.log("error aqui 2", error.response.data);
                    console.log("error aqui 3", error.response.status);
                    console.log("error aqui 4", error.response.headers);
                } else if (error.request) {
                    console.log("error aqui 5", error.request);
                } else {
                    console.log("error aqui 6", error.message);
                }
                console.log("error aqui 7", error.config);

                if (typeof self.props.onEventoErro === "function") {
                    self.props.onEventoErro(
                        "2 Não foi possível encaminhar a solicitação!"
                    );
                }
            });
    };

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo !== 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    validaTamanhoArquivos() {
        // Validando tamanho dos arquivos
        if (this.state.files.length) {
            var size = 0;
            for (var i = 0; i < this.state.files.length; i++) {
                const file = this.state.files[i];
                size += file.size;
            }

            if (size > config.LIMITE_UPLOAD_BYTES) {
                this.autoCloseAlert(
                    "Erro",
                    "Arquivos de upload excedem o limite de " +
                        config.LIMITE_UPLOAD_MB +
                        "!",
                    true,
                    0
                );
                return false;
            }
        }
        return true;
    }

    enviaAnexos = () => {
        const self = this;

        var headers = {
            "Content-Type": "application/json",
            Authorization: config.AUTH_TOKEN
        };

        // Validando tamanho dos arquivos
        if (this.state.files.length) {
            var size = 0;
            for (var i = 0; i < this.state.files.length; i++) {
                const file = this.state.files[i];
                size += file.size;
            }

            if (size > 2097152) {
                this.autoCloseAlert(
                    "Erro",
                    "Arquivos de upload excedem o limite!",
                    true,
                    0
                );
                return;
            }
        }

        // Enviando anexos
        if (self.state.files.length) {
            self.state.files.forEach(file => {
                const data = new FormData();
                data.append("path", file, file.name);
                data.append("descricao", file.name);
                data.append("solicitacao_id", self.props.solicitacao.id);
                axios
                    .post(config.API + config.ANEXOS_QUERY, data, {
                        onUploadProgress: ProgressEvent => {
                            self.setState({
                                loaded:
                                    (ProgressEvent.loaded /
                                        ProgressEvent.total) *
                                    100
                            });
                        },
                        headers: headers
                    })
                    .catch(function(error) {
                        var mensagem = error;
                        console.log("error aqui 1", error);
                        if (error.response) {
                            mensagem = error.response.data;
                            console.log("error aqui 2", error.response.data);
                            console.log("error aqui 3", error.response.status);
                            console.log("error aqui 4", error.response.headers);
                        } else if (error.request) {
                            console.log("error aqui 5", error.request);
                        } else {
                            mensagem = error.message;
                            console.log("error aqui 6", error.message);
                        }
                        console.log("error aqui 7", error.config);

                        if (typeof self.props.onEventoErro === "function") {
                            self.props.onEventoErro(
                                "Erro enviando anexos! Detalhes: " + mensagem
                            );
                        }
                    });
            });
        }
    };

    onDrop = files => {
        this.setState({ files });
    };

    onComentar = () => {
        this.onPressComentario();
    };

    onPressComentario = () => {
        const self = this;

        if (this.validaTamanhoArquivos()) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (!this.state.comentario) {
                this.props.onEventoErro("Preencha o campo Comentário!");
                return;
            }

            // Enviando outros dados do chamado
            const postdata = {
                solicitacao_id: this.props.solicitacao.id,
                comentario: this.state.comentario,
                notificar_por_email: false,
                analista_departamento: this.props.usuario.fullname,
                usuario_email: this.props.usuario.username,
                pessoa_id: null,
                is_solicitante: solicitacoes.comparaEmailCaseInsensitive(
                    this.props.usuario.username,
                    this.props.solicitacao.solicitante.email
                )
                    ? true
                    : false
            };

            //console.log('analista atendimento:', this.props.usuario.fullname);

            axios
                .post(config.API + "comentarios/", postdata, {
                    headers: headers
                })
                .then(function(response) {
                    if (self.state.files.length > 0) {
                        self.enviaAnexos();
                    }

                    if (typeof self.props.onEventoSucesso === "function") {
                        self.props.onEventoSucesso(response.data.descricao);
                    }
                })
                .catch(function(error) {
                    if (typeof self.props.onEventoErro === "function") {
                        self.props.onEventoErro(
                            "Não foi possível encaminhar a solicitação!"
                        );
                    }
                });
        }
    };

    onResolver = () => {
        const self = this;

        if (this.validaTamanhoArquivos()) {
            var headers = {
                "Content-Type": "application/json",
                Authorization: config.AUTH_TOKEN
            };

            if (typeof this.props.onEvento === "function") {
                this.props.onEvento();
            }

            if (!this.state.comentario) {
                this.props.onEventoErro("Preencha o campo Comentário!");
                return;
            }

            console.log("usuario_email", this.props.usuario.username);

            // Enviando outros dados do chamado
            const postdata = {
                solicitacao_id: this.props.solicitacao.id,
                comentario: this.state.comentario,
                notificar_por_email: false,
                analista_departamento: this.props.usuario.fullname,
                usuario_email: this.props.usuario.username,
                pessoa_id: null,
                is_solicitante: solicitacoes.comparaEmailCaseInsensitive(
                    this.props.usuario.username,
                    this.props.solicitacao.solicitante.email
                )
                    ? true
                    : false,
                resolver: true
            };

            //console.log('analista atendimento:', this.props.usuario.fullname);

            axios
                .post(config.API + "comentarios/", postdata, {
                    headers: headers
                })
                .then(function(response) {
                    if (self.state.files.length > 0) {
                        self.enviaAnexos();
                    }

                    if (typeof self.props.onEventoSucesso === "function") {
                        self.props.onEventoSucesso(response.data.descricao);
                    }
                })
                .catch(function(error) {
                    if (typeof self.props.onEventoErro === "function") {
                        self.props.onEventoErro(
                            "Não foi possível encaminhar a solicitação!"
                        );
                    }
                });
        }
    };

    render() {
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        //console.log('cheguei aqui no formulário novo de comentario');

        var textoBotao = "Prosseguir";

        if ([19, 25].includes(this.props.solicitacao.submotivo.id)) {
            if (this.props.entrega) {
                return (
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={4}>
                                <p>Data de Recebimento</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <InputMask
                                            className="form-control"
                                            placeholder={""}
                                            type="text"
                                            onChange={event =>
                                                this.setState({
                                                    data_recebimento:
                                                        event.target.value
                                                })
                                            }
                                            mask={"99/99/9999"}
                                            value={this.state.data_recebimento}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Quem recebeu?</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value={this.state.quem_recebeu}
                                            onChange={event =>
                                                this.setState({
                                                    quem_recebeu:
                                                        event.target.value
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ textAlign: "right" }}>
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.onPressEncaminharRecebimento}
                                >
                                    {textoBotao}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }

            return (
                <div
                    style={{
                        backgroundColor: "rgba(72, 195, 233, 0.5)",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginBottom: 20
                    }}
                >
                    <Row>
                        <Col md={4}>
                            <p>Código de Rastreio</p>
                        </Col>
                        <Col md={8}>
                            <Form action="#" method="#">
                                <FormGroup>
                                    <Input
                                        type="text"
                                        value={this.state.codigo_hawb}
                                        onChange={event =>
                                            this.setState({
                                                codigo_hawb: event.target.value
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <p>Data de Envio</p>
                        </Col>
                        <Col md={8}>
                            <Form action="#" method="#">
                                <FormGroup>
                                    <InputMask
                                        className="form-control"
                                        placeholder={""}
                                        type="text"
                                        onChange={event =>
                                            this.setState({
                                                data_entrega_destino:
                                                    event.target.value
                                            })
                                        }
                                        mask={"99/99/9999"}
                                        value={this.state.data_entrega_destino}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: "right" }}>
                            <Button
                                className="btn btn-primary"
                                onClick={this.onPressEncaminharLogistica}
                            >
                                {textoBotao}
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        } else if (
            [24, 26, 27, 15].includes(this.props.solicitacao.submotivo.id)
        ) {
            if (this.props.solicitacao.etapa == 2) {
                return (
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={4}>
                                <p>Código de e-Ticket</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value={this.state.codigo_hawb}
                                            onChange={event =>
                                                this.setState({
                                                    codigo_hawb:
                                                        event.target.value
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Data de Envio</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <InputMask
                                            className="form-control"
                                            placeholder={""}
                                            type="text"
                                            onChange={event =>
                                                this.setState({
                                                    data_entrega_destino:
                                                        event.target.value
                                                })
                                            }
                                            mask={"99/99/9999"}
                                            value={
                                                this.state.data_entrega_destino
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ textAlign: "right" }}>
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.onPressEncaminharETicket}
                                >
                                    {textoBotao}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            } else if (this.props.solicitacao.etapa == 3) {
                return (
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={6}>
                                <Button
                                    className="btn btn-danger"
                                    onClick={this.onPressETicketExpirado}
                                >
                                    E-Ticket Expirado
                                </Button>
                            </Col>
                            <Col md={6} style={{ textAlign: "right" }}>
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.onPressETicketEnviado}
                                >
                                    E-Ticket Utilizado
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            } else if (this.props.solicitacao.etapa == 5) {
                return (
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={4}>
                                <p>Código de Rastreio</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value={this.state.codigo_hawb}
                                            onChange={event =>
                                                this.setState({
                                                    codigo_hawb:
                                                        event.target.value
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Data de Envio</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <InputMask
                                            className="form-control"
                                            placeholder={""}
                                            type="text"
                                            onChange={event =>
                                                this.setState({
                                                    data_entrega_destino:
                                                        event.target.value
                                                })
                                            }
                                            mask={"99/99/9999"}
                                            value={
                                                this.state.data_entrega_destino
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ textAlign: "right" }}>
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.onPressEncaminharLogistica2}
                                >
                                    {textoBotao}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            } else if (this.props.solicitacao.etapa == 6) {
                return (
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={4}>
                                <p>Data de Recebimento</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <InputMask
                                            className="form-control"
                                            placeholder={""}
                                            type="text"
                                            onChange={event =>
                                                this.setState({
                                                    data_recebimento:
                                                        event.target.value
                                                })
                                            }
                                            mask={"99/99/9999"}
                                            value={this.state.data_recebimento}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <p>Quem recebeu?</p>
                            </Col>
                            <Col md={8}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            value={this.state.quem_recebeu}
                                            onChange={event =>
                                                this.setState({
                                                    quem_recebeu:
                                                        event.target.value
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ textAlign: "right" }}>
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.onPressEncaminharRecebimento2}
                                >
                                    {textoBotao}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }
        } else if (this.props.solicitacao.submotivo.motivo_id === 4) {
            return (
                <>
                    {this.state.alert}
                    <div
                        style={{
                            backgroundColor: "rgba(72, 195, 233, 0.5)",
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginBottom: 20
                        }}
                    >
                        <Row>
                            <Col md={12}>
                                <p>Comentários:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form action="#" method="#">
                                    <FormGroup>
                                        <Input
                                            type="textarea"
                                            value={this.state.comentario}
                                            onChange={event => {
                                                //console.log('event comentarios', event.target);
                                                if (
                                                    this.state.comentario &&
                                                    this.state.comentario
                                                        .length == 200
                                                ) {
                                                    return;
                                                }
                                                this.setState({
                                                    comentario:
                                                        event.target.value
                                                });
                                            }}
                                            onKeyDown={event => {
                                                console.log(
                                                    "event keycode",
                                                    event.key
                                                );
                                                if (
                                                    this.state.comentario &&
                                                    this.state.comentario
                                                        .length == 200 &&
                                                    event.key == "Backspace"
                                                ) {
                                                    const comentario = this.state.comentario.substr(
                                                        0,
                                                        this.state.comentario
                                                            .length - 1
                                                    );
                                                    this.setState({
                                                        comentario
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    <p
                                        style={{
                                            fontSize: "10px",
                                            opacity: 0.8
                                        }}
                                    >
                                        Caracteres restantes:{" "}
                                        {this.state.comentario
                                            ? 200 - this.state.comentario.length
                                            : 200}
                                    </p>
                                </Form>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Dropzone onDrop={this.onDrop}>
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive
                                        }) => {
                                            return (
                                                <div
                                                    {...getRootProps()}
                                                    className={"form-control"}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {
                                                        <p>
                                                            <i className="nc-icon nc-cloud-upload-94" />
                                                            &nbsp;&nbsp;
                                                            <span
                                                                style={{
                                                                    borderBottomWidth: 1,
                                                                    borderBottomColor:
                                                                        "black",
                                                                    borderBottomStyle:
                                                                        "dotted"
                                                                }}
                                                            >
                                                                Arraste arquivos
                                                                aqui para
                                                                anexar, ou
                                                                clique para
                                                                pesquisar
                                                            </span>
                                                            {files.length >
                                                                0 && (
                                                                <FormText
                                                                    color="default"
                                                                    tag="span"
                                                                >
                                                                    <label>
                                                                        Arquivos
                                                                    </label>
                                                                    <ul>
                                                                        {files}
                                                                    </ul>
                                                                </FormText>
                                                            )}
                                                        </p>
                                                    }
                                                </div>
                                            );
                                        }}
                                    </Dropzone>
                                </FormGroup>
                            </Col>
                        </Row>
                        {(this.props.solicitacao.status.id == 7 ||
                            (this.props.solicitacao.status.id != 7 &&
                                this.props.solicitacao.submotivo.id != 19)) && (
                            <>
                                {!solicitacoes.comparaEmailCaseInsensitive(
                                    this.props.solicitacao.solicitante.email,
                                    this.props.usuario.username
                                ) && (
                                    <Row>
                                        <Col
                                            md={6}
                                            style={{ textAlign: "left" }}
                                        >
                                            <Button
                                                className="btn btn-success"
                                                onClick={this.onResolver}
                                            >
                                                Resolvido
                                            </Button>
                                        </Col>
                                        <Col
                                            md={6}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                className="btn btn-primary"
                                                onClick={this.onComentar}
                                            >
                                                Pendenciar
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                {solicitacoes.comparaEmailCaseInsensitive(
                                    this.props.solicitacao.solicitante.email,
                                    this.props.usuario.username
                                ) && (
                                    <Row>
                                        <Col
                                            md={12}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                className="btn btn-primary"
                                                onClick={this.onComentar}
                                            >
                                                Comentar
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                        {this.props.solicitacao.status.id != 7 &&
                            this.props.solicitacao.submotivo.id == 19 && (
                                <Row>
                                    <Col md={12} style={{ textAlign: "right" }}>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={this.onComentar}
                                        >
                                            {textoBotao}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                    </div>
                </>
            );
        }

        return null;
    }
}
